export const isValidJwt = (jwt) => {
  if (!jwt || jwt.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
  const now = new Date()
  return now < exp
}

export const jwtGetExpirationDate = (jwt) => {
  if (!jwt || jwt.split('.').length < 3) {
      return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch

  return exp
}

export const jwtGetId = (jwt) => {
  if (!jwt || jwt.split('.').length < 3) {
      return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const id = data._id
  return id
}

export const jwtGetAdmin = (jwt) => {
  if (!jwt || jwt.split('.').length < 3) {
      return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const id = data.admin
  return id
}

export const jwtGetData = (jwt) => {
  if (!jwt || jwt.split('.').length < 3) {
      return false
  }
  return JSON.parse(atob(jwt.split('.')[1]))
  
}