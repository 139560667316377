const coreData = {

  data(){
    return {
      mediaImage: null,
      connection: null,
      heartbeat: null,
      lastScrollTop: 0,
      lock: false,
      refreshActive: false
    }
  },

  computed: {
    customerquestions(){
      return this.$store.getters.customerquestions
    },
    questions(){
      return this.$store.getters.questions
    },
    scrollLock(){
      return this.$store.getters.scrollLock
    },
    isAdmin(){
      return this.$store.getters.isAdmin
    },
    autoscroll(){
      return this.$store.getters.autoscroll
    },
    autoscroll2(){
      return this.$store.getters.autoscroll2
    },
    updating(){
      return this.$store.getters.updating
    },
    authError(){
      return this.$store.getters.authError
    },
    authMessage(){
      return this.$store.getters.authMessage
    },
    orders(){
      return this.$store.getters.orders
    },
    coupons(){
      return this.$store.getters.coupons
    },
    giveaways(){
      return this.$store.getters.giveaways
    },
    customers(){
      return this.$store.getters.customers
    },
    emailtemplates(){
      return this.$store.getters.emailtemplates
    },
    templatesOptions(){
      var array = []
        if(this.emailtemplates){
          this.emailtemplates.forEach(element => {
            array.push({value: element._id, text: element.title})
          });
        }
        return array
    }
    // wsConnection(){
    //   return this.$store.getters.wsConnection
    // }
  },
  mounted() {
    this.scrollToTop()
    if(this.isAdmin){
      this.refreshData()
    }
   
  },
  created(){

  },
  beforeDestroy(){
    clearInterval(this.heartbeat)
  },
  methods: {
    loggedIn(){
      if(localStorage.getItem('token')) return true

      return false
    },
    getDaysInMonth(month, year) {
      var date = new Date(year, month, 1);
      var days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date).getDate() + '.' + (new Date(date).getMonth() + 1));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getDatesInMonth(month, year) {
      var date = new Date(year, month, 1);
      var days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    refreshData(){
      this.$store.dispatch('getCustomers')
      this.$store.dispatch('getOrders')
      this.$store.dispatch('getCoupons')
      this.$store.dispatch('getGiveaways')
      this.$store.dispatch('getEmailtemplates')
      var vm = this
      setTimeout(function(){ vm.refreshActive = false; }, 1500);
    },
    calcAnswerPercentage(option, question){
      var count = 0
      question.answers.forEach(element => {
          if(element.answer == option) count += 1
      });
      return count
    },
    checkAnswers(id){
      if(this.questions){
        if(this.questions.find(el => el._id == id).answers){
          if( this.questions.find(el => el._id == id).answers.find(el => el.token == localStorage.getItem('token') )){
            return false
          }
        }
      }
      return true
    },
    getAnswer(id){
      if(this.questions){
        if(this.questions.find(el => el._id == id).answers){
          if( this.questions.find(el => el._id == id).answers.find(el => el.token == localStorage.getItem('token') )){
            return this.questions.find(el => el._id == id).answers.find(el => el.token == localStorage.getItem('token')).answer
          }
        }
      }
      return ''
    },
    sendAnswer(id, answer){
      var payload = {
        _id: id,
        answer: answer,
        token: localStorage.getItem('token'),
        sender: localStorage.getItem('username')
      }
      this.$store.dispatch('sendAnswer', payload)
    },
    scrollToTop(){
      window.scrollTo({top: 0});
    },

    keepalive(){
      if(this.wsConnection){
        if (!this.isOpen(this.wsConnection)){

          this.$store.commit('connected', false)
          var connection = new WebSocket(process.env.VUE_APP_WEBSOCKET_BASEURL)
          this.$store.commit('wsConnection', connection)
          if(connection){
            this.$store.commit('connected', true)
          }
        }else{
          this.$store.commit('connected', true)

          this.wsConnection.send(JSON.stringify({heartbeat: true}))
        }
        
      }
    },
    isOpen(ws) { return ws.readyState === ws.OPEN },

    scrollToEndChat() {
        setTimeout(function(){ 
          var container = document.getElementById("chatWindow")
          if(container != null){
            container.scrollTo({top: container.scrollHeight, behavior: 'smooth'});
          }
        }, 100);     
        setTimeout(function(){ 
          var container = document.getElementById("chatWindow2")
          if(container != null){
            container.scrollTo({top: container.scrollHeight, behavior: 'smooth'});
          }
        }, 100);       
    },
  }
}

export default coreData