import axiosAuth from '@/shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        darkMode: true,
        colorSchemeSetByUser: '',
        preferedColorScheme: null,
        storeTest: '',
        popLogin: false,
        updating: false,
        kw: null,
        loadingData: null,
        scrollLock: false
    }
}

const state = getDefaultState()

const getters = {
    storeTest: state => {
        return state.storeTest
    },
    preferedColorScheme: state => {
        return state.preferedColorScheme
    },
    darkMode: state => {
        return state.darkMode;
    },
    kw: state => {
        return state.kw;
    },
    colorSchemeSetByUser: state => {
        return state.colorSchemeSetByUser
    },
    testAccess: state => {
        return state.testAccess
    },
    popLogin: state => {
        return state.popLogin
    },
    updating: state => {
        return state.updating
    },
    loadingData: state => {
        return state.loadingData
    },
    scrollLock: state => {
        return state.scrollLock
    }
}

const mutations = {
    storeTest (state, payload) {
        state.storeTest = payload
    },
    kw (state, payload) {
        state.kw = payload
    },
    resetState (state) {
        Object.assign(state, getDefaultState())
    },
    asideToggled: (state, payload) => {
        state.asideToggled = payload
    },
    testAccess: (state, payload) => {
        state.testAccess = payload
    },
    preferedColorScheme: (state, colorScheme) => {
        state.preferedColorScheme = colorScheme
    },
    darkMode: (state, colorScheme) => {
        state.darkMode = colorScheme
    },
    colorSchemeSetByUser: (state, colorScheme) => {
        state.colorSchemeSetByUser = colorScheme
    },
    popLogin: (state, payload) => {
        state.popLogin = payload
    },
    updating: (state, payload) => {
        state.updating = payload
    },
    loadingData: (state, payload) => {
        state.loadingData = payload
    },
    scrollLock: (state, payload) => {
        state.scrollLock = payload
    },
}

const actions = {
    resetToasts: ({commit}) => {
        commit('shiftmodelUpdated', null)
    },

    setTheme: ({commit, dispatch}, colorScheme) => {
        commit('colorSchemeSetByUser', colorScheme)
        dispatch('preferedColorScheme')
    },
    darkMode: ({commit}) => {
        commit('darkMode')
    },
    preferedColorScheme: ({commit}) => {
        const preferedColorScheme = window.matchMedia("(prefers-color-scheme: dark)")
        if(!state.colorSchemeSetByUser){
            if (preferedColorScheme.matches) {
                commit('darkMode', true)
            } else {
                commit('darkMode', false)
            }
        }else{
            if(state.colorSchemeSetByUser == 'dark'){
                commit('darkMode', true)
            }else{
                commit('darkMode', false)
            }
        }
    },

    sendTestmail ({commit}, payload) {
        commit('updating', true)
        axiosAuth.post('/testmail', payload)
            .then(
                res => {
                    commit('updating', false)
                    console.log(res)
                    commit('trash', res)
                }
            )
            .catch(
            () => {
                commit('updating', false)
            }
        )
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}