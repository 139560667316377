<template>
  <div class="home" style="padding-top: 90px; margin-bottom: 150px" >
    <b-row class="m-0 justify-content-center">
      <img src="https://avydan.appfreak.de/wp-content/uploads/2020/10/AvyDan_black_normal.png" alt="Media-lf Logo"/>
    </b-row>
    <b-row class="m-0 mx-5 mt-5 justify-content-center">
      <h1 class="text-center"><span v-html="verificationMessage"></span></h1>
    </b-row>
  </div>
</template>

<script>

// import coreData from '@/mixins/coreDataMixin'

export default {
  name: 'Confirm',
  // mixins: [coreData],
  data(){
    return {
      
    }
  },
  components: {

  },
  computed:{
    verificationMessage(){
      return this.$store.getters.verificationMessage
    }
  },
  watch: {
  },
  mounted(){
    this.$store.dispatch('verifyCustomer', {verification_token: this.$route.params.token})
  },
  methods: {
   
  }
}
</script>
