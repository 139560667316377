    import axiosAuth from '@/shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            coupons: []
        }
    }
    
    const state = getDefaultState()

    const getters = {
        coupons: state => {
            return state.coupons
        }
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        coupons: (state, payload) => {
            state.coupons = payload
        }
    }

    const actions = {
       
        getCoupons: ({commit, dispatch}) =>{
            commit('updating', true)
            axiosAuth.get('/coupons')
            .then(
                res => {
                    commit('updating', false)
                    commit('coupons', res.data)
                }
              )
              .catch(
                error => {
                  commit('updating', false)
                    if(error.response){
                        if(error.response.status == 401) dispatch('logout')
                    }
                }
              )
        },

        postCoupon ({commit, dispatch}, payload) {
            commit('updating', true)
            axiosAuth.post('/coupons', payload)
                .then(
                    res => {
                        commit('trash', res)
                        commit('updating', false)
                        dispatch('getcoupons')
                    }
                )
                .catch(
                () => {
                    commit('updating', false)
                }
                )
        },

        postCouponSingle ({commit, dispatch}, payload) {
            commit('updating', true)
            axiosAuth.post('/coupons/single', payload)
                .then(
                    res => {
                        commit('trash', res)
                        commit('updating', false)
                        dispatch('getCoupons')
                    }
                )
                .catch(
                () => {
                    commit('updating', false)
                }
                )
        },

        updateCoupon: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.patch('/coupons/'+ payload._id, payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getcoupons')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        },

        deleteCoupon: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.delete('/coupons/'+ payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getCoupons')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }