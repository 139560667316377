<template>
  <div class="home" style="padding-top: 90px; margin-bottom: 150px" >
    <b-row class="m-0 justify-content-center" v-if="isAdmin">
    <div class="w-100" style="max-width: 1700px">
 
      <b-row class="justify-content-center m-0 mb-2">
        <h1 class="text-secondary">Avy&Dan Gewinnspiel Randomizer </h1>        
      </b-row>
      <b-row class="justify-content-center m-0 mb-5" v-if="getGiveaway()">
        <h3 class="text-secondary">Gewinnspiel: {{ getGiveaway().title }} </h3>        
      </b-row>

      <div>
        <div class="showcase-invert bg-white  m-2 mx-4 mb-5"  style="min-width: 95%" v-if="getGiveaway()">
          <div class="showcase-header py-4 linear-info text-white" style="position: relative" v-b-toggle="'collapse-giveaway'">
            <h4 class="text-center m-0">Gewinnspiel bearbeiten</h4>
          </div>
            <b-collapse :id="'collapse-giveaway'">
              <div v-if="!getGiveaway().done">
                <div class="mt-3 w-100">
                  <b-form @submit.prevent="updateGiveaway(getGiveaway())">
                    <b-row class="m-0 justify-content-start">
                      <b-col cols="12" md="8" class="mb-5">
                        <p class="mt-4 m-0 text-secondary"><strong>Einstellungen Gewinnspiel</strong></p>
                        <hr class="mb-2 mt-0">
                        <b-form-group
                          label="Gewinnspieltitel:"
                          description="z.B.: Avy&Dan Gewinnspiel 2021"
                        >
                          <b-form-input
                            v-model="getGiveaway().title"
                            type="text"
                            placeholder="Titel eingeben..."
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Anzahl mögliche Gewinner:" >
                          <b-form-input
                            v-model="getGiveaway().amount_winners"
                            type="number"
                            step="1"
                            min="1"
                            placeholder="Anzahl eingeben..."
                            required
                          ></b-form-input>
                        </b-form-group>

                        <small class="mt-4 m-0 text-secondary">Einstellungen Gewinner</small>
                        <hr class="mb-2 mt-0">

                        <b-form-group :description="'Gewinner müssen innerhalb von ' + (getGiveaway().amount_hours ? getGiveaway().amount_hours : 'X') + ' Stunden die E-Mail bestätigen'">
                          <b-form-checkbox v-model="getGiveaway().request_verification">Gewinner muss E-Mail bestätigen</b-form-checkbox>
                        </b-form-group>

                        <b-form-group class="mx-4" label="Anzahl Stunden für die E-Mail-Bestätigung:" v-if="getGiveaway().request_verification">
                          <b-form-input
                            v-model="getGiveaway().amount_hours"
                            type="number"
                            step="1"
                            min="1"
                            placeholder="Anzahl eingeben..."
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label="E-Mail-Template Gewinner:" >
                          <b-form-select
                            id="input-3"
                            v-model="getGiveaway().email_template_winner"
                            :options="templatesOptions"
                            required
                          ></b-form-select>
                        </b-form-group>

                        <small class="mt-4 m-0 text-secondary">Einstellungen Verlierer</small>
                        <hr class="mb-2 mt-0">

                        <b-form-group class="">
                          <b-form-checkbox v-model="getGiveaway().notify_losers">Verlierer werden benachrichtigt</b-form-checkbox>
                        </b-form-group>

                        <b-form-group class="mx-4" label="E-Mail-Template Verlierer:" v-if="getGiveaway().notify_losers">
                          <b-form-select
                            id="input-3"
                            v-model="getGiveaway().email_template_loser"
                            :options="templatesOptions"
                            required
                          ></b-form-select>
                        </b-form-group>
                      </b-col>


                      <b-col cols="12" md="5">
                        <p class="mt-4 m-0 text-secondary"><strong>Einstellungen Gewinnergutschein</strong></p>
                        <hr class="mb-2 mt-0">
                        <b-form-group label="Code-Typ:" >
                          <b-form-radio required v-model="getGiveaway().voucher_winner.code_type" name="A" value="A">Vorname_Nachname_{{ v4.toString().substring(0,7) }}</b-form-radio>
                          <b-form-radio required v-model="getGiveaway().voucher_winner.code_type" name="A" value="B">E-Mail_{{ v4.toString().substring(0,7) }}</b-form-radio>
                          <b-form-radio required v-model="getGiveaway().voucher_winner.code_type" name="A" value="C">{{ v4.toString().substring(0,7) }}</b-form-radio>
                        </b-form-group>


                        <b-form-group
                          label="Beschreibung"
                          description="z.B.: Gewinnspiel XY"
                        >
                          <b-form-input
                            v-model="getGiveaway().voucher_winner.description"
                            type="text"
                            required
                            size="sm"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Gutscheintyp:" >
                          <b-form-select
                            v-model="getGiveaway().voucher_winner.discount_type"
                            :options="discount_types"
                            size="sm"
                            required
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group  label="Gutscheinbetrag">
                          <b-form-input
                            v-model="getGiveaway().voucher_winner.amount"
                            type="number"
                            required
                            size="sm"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group  label="Gültig bis:">
                          <b-form-input
                            v-model="getGiveaway().voucher_winner.date_expires"
                            type="date"
                            size="sm"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group>
                            <b-form-checkbox v-model="getGiveaway().voucher_winner.free_shipping">Gratis Lieferung</b-form-checkbox>
                        </b-form-group>
                        <p class="mt-4 m-0 text-secondary"><strong>Hinweis: Es wird pro Gewinner JEWEILS ein Gutschein angelegt!</strong></p>
                        <hr class="mb-2 mt-0">
                      </b-col>

                      <b-col cols="0" md="1"></b-col>

                      <b-col cols="12" md="5" v-if="getGiveaway().notify_losers">
                        <p class="mt-4 m-0 text-secondary"><strong>Einstellungen Verlierergutschein</strong></p>
                        <hr class="mb-2 mt-0">
                        <b-form-group label="Code-Typ:" >
                          <!-- <b-form-radio :required="getGiveaway().notify_losers" v-model="getGiveaway().voucher_loser.code_type" name="B" value="A">Vorname_Nachname_{{ v4.toString().substring(0,7) }}</b-form-radio>
                          <b-form-radio :required="getGiveaway().notify_losers" v-model="getGiveaway().voucher_loser.code_type" name="B" value="B">E-Mail_{{ v4.toString().substring(0,7) }}</b-form-radio> -->
                          <b-form-radio :required="getGiveaway().notify_losers" v-model="getGiveaway().voucher_loser.code_type" name="B" value="C">{{ v4.toString().substring(0,7) }}</b-form-radio>
                          <b-form-radio :required="getGiveaway().notify_losers" v-model="getGiveaway().voucher_loser.code_type" name="B" value="D">
                            <b-form-input
                              style="width: 150%"
                              id="input-1"
                              v-model="getGiveaway().voucher_loser.custom"
                              type="text"
                              placeholder="Eigenen Text eintragen..."
                              :required="getGiveaway().voucher_loser.code_type == 'D'"
                              size="sm"
                            ></b-form-input>
                          </b-form-radio>
                        </b-form-group>


                        <b-form-group
                          label="Beschreibung"
                          description="z.B.: Gewinnspiel XY"
                        >
                          <b-form-input
                            v-model="getGiveaway().voucher_loser.description"
                            type="text"
                            :required="getGiveaway().notify_losers"
                            size="sm"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Gutscheintyp:" >
                          <b-form-select
                            v-model="getGiveaway().voucher_loser.discount_type"
                            :options="discount_types"
                            size="sm"
                            :required="getGiveaway().notify_losers"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group  label="Gutscheinbetrag">
                          <b-form-input
                            v-model="getGiveaway().voucher_loser.amount"
                            type="number"
                            :required="getGiveaway().notify_losers"
                            size="sm"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group  label="Gültig bis:">
                          <b-form-input
                            v-model="getGiveaway().voucher_loser.date_expires"
                            type="date"
                            size="sm"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group>
                            <b-form-checkbox v-model="getGiveaway().voucher_loser.free_shipping">Gratis Lieferung</b-form-checkbox>
                        </b-form-group>
                        <p class="mt-4 m-0 text-secondary"><strong>Hinweis: Es wird INSGESAMT ein Gutschein angelegt!</strong></p>
                        <hr class="mb-2 mt-0">
                      </b-col>
                      
                    </b-row>
                    <b-button class="mt-3 mb-5" type="submit" variant="primary" >Gewinnspiel speichern</b-button>
                  </b-form>
                  <!-- <b-card class="mt-3" header="Form Data Result">
                    <pre class="m-0">{{ form }}</pre>
                  </b-card> -->
                </div>
                <b-button size="sm" variant="danger" class="ml-2 mb-2" @click="$store.dispatch('deleteGiveaway', getGiveaway()._id)">
                  <b-icon v-if="!updating" icon="trash" aria-hidden="true"></b-icon> <b-spinner v-if="updating" small label="Spinning"></b-spinner> Löschen
                </b-button>
              </div>
              <div v-else>
                <h3 class="my-5 text-center text-success">Gewinnspiel wurde beendet</h3>
              </div>
            </b-collapse>
        </div>
      </div>
      <div v-if="getGiveaway()">
        <div class="showcase-invert bg-white  m-2 mx-4"  style="min-width: 95%" >
          <div class="showcase-header py-4 linear-info text-white" style="position: relative">
            <h4 class="text-center m-0">Teilnehmer anlegen / importieren</h4>
          </div>
          <b-row class="m-0 justify-content-center my-5">
             
            <b-col cols="12" lg="6" v-if="!getGiveaway().done">
            <div class="agenda  p-3" style="border-radius: 10px">
              <h4 class="mb-4">Import aus Datei</h4>
              <vue-csv-import v-model="parseCsv" :headers="true" :map-fields="{firstname: 'Vorname', lastname: 'Nachname', email: 'E-Mail'}"></vue-csv-import>          
              <b-button class="mx-1" variant="primary" v-if="parseCsv" @click="startImport">hinzufügen</b-button>
            </div>
            </b-col>
            <b-col cols="12" lg="6" v-if="!getGiveaway().done">
            <div class="agenda  p-3" style="border-radius: 10px">
              <h4 class="mb-4">Teilnehmer hinzufügen</h4>
              <b-form @submit.prevent="postCustomer">
              <b-row class="m-0 justify-content-start">

              <b-form-group
                  :state="authError != null ? false: null"
                  class="mx-1"
                  id="input-group-1"
                  label="E-Mail-Adresse:"
                  label-for="input-1"
                >
                  <b-form-input
                    :state="authError != null ? false: null"
                    id="input-1"
                    v-model="newCustomer.email"
                    type="email"
                    required
                    placeholder="E-Mail-Adresse eingeben"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="authError != null ? false: null">
                  {{authMessage}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  class="mx-1"
                  id="input-group-2" 
                  label="Vorname:" 
                  label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="newCustomer.firstname"
                    placeholder="Vorname eingeben"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  class="mx-1"
                  id="input-group-2" 
                  label="Nachname:" 
                  label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="newCustomer.lastname"
                    placeholder="Nachname eingeben"
                  ></b-form-input>
                </b-form-group>

                </b-row>
                <b-button class="mx-1" variant="primary" type="submit">hinzufügen</b-button>
              </b-form>
            </div>
            </b-col>

            <div v-else>
              <h3 class="my-1 text-center text-success">Gewinnspiel wurde beendet</h3>
            </div>
          </b-row>
        </div>

        <div class="showcase-invert bg-white  m-2 mx-4 mt-5"  style="min-width: 95%" v-if="filteredCustomers.length > 0 && getGiveaway()">
          <div class="showcase-header py-4 linear-info text-white" style="position: relative">
            <h4 v-if="!getGiveaway().done" class="text-center m-0 text-white">Gewinnspiel Teilnehmer ({{ filteredCustomers.length }})</h4>
            <h4 v-else class="text-center m-0 text-white">Gezogene Gewinner: ({{ filteredCustomers.filter(el => el.won).length }})</h4>
          </div>
          <b-row class="m-0 justify-content-center" v-if="filteredCustomers && filteredCustomers">

            <div class="p-4 w-100">
              <b-table hover="hover" striped="striped" v-if="filteredCustomers.length > 0"  borderless small="small" responsive="sm" :items="filteredCustomers" :fields="customerfields" :current-page="currentPage" :per-page="perPage" @row-clicked="rowClicked">
              <template v-slot:cell(firstname)="data">
                <p class="m-0 mt-2">{{ data.item.firstname }}</p>
              </template>
              <template v-slot:cell(lastname)="data">
                <p class="m-0 mt-2">{{ data.item.lastname }}</p>
              </template>
              <template v-slot:cell(email)="data">
                <p class="m-0 mt-2">{{ data.item.email }}</p>
              </template>
              <template v-slot:cell(notified)="data">
                <p class="m-0 text-center">
                  <b-icon v-if="data.item.notified" class="text-success h1 m-0 p-0" icon="check" aria-hidden="true"></b-icon>
                  <b-icon v-else class="text-danger h1 m-0 p-0" icon="x" aria-hidden="true"></b-icon>
                </p>
              </template>
              <template v-slot:cell(verified)="data">
                <p class="m-0 text-center">
                  <b-icon v-if="data.item.verified" class="text-success h1 m-0 p-0" icon="check" aria-hidden="true"></b-icon>
                  <b-icon v-else class="text-danger h1 m-0 p-0" icon="x" aria-hidden="true"></b-icon>
                </p>
              </template>
              <template v-slot:cell(on_time)="data">
                <p class="m-0 text-center">
                  <b-icon v-if="data.item.on_time" class="text-success h1 m-0 p-0" icon="check" aria-hidden="true"></b-icon>
                  <b-icon v-if="data.item.on_time === false" class="text-danger h1 m-0 p-0" icon="x" aria-hidden="true"></b-icon>
                  <b-icon v-if="data.item.on_time === null" class="text-info h4 m-0 mt-2 p-0" icon="clock" aria-hidden="true"></b-icon>
                </p>
              </template>
              <template v-slot:cell(won)="data">
                <p class="m-0 text-center">
                  <b-icon v-if="data.item.won === true" class="text-success h1 m-0 p-0" icon="check" aria-hidden="true"></b-icon>
                  <b-icon v-if="data.item.won === false" class="text-danger h1 m-0 p-0" icon="x" aria-hidden="true"></b-icon>
                  <span v-if="data.item.won === null"><div  class="mt-2" >offen</div> </span>
                </p>
              </template>
              <template v-slot:cell(delete)="data">
                <div class="justify-content-center pt-2" v-if="filteredCustomers.length > 1">
                  <b-button size="sm" variant="danger" class="ml-2 mb-2" @click="deleteCustomer(data.item._id)">
                    <b-icon v-if="!updating" icon="trash" aria-hidden="true"></b-icon> <b-spinner v-if="updating" small label="Spinning"></b-spinner> Löschen
                  </b-button>
                </div>
              </template>
              </b-table>
              <nav>
                <b-pagination size="sm" :total-rows="filteredCustomers.length" :per-page="perPage" v-model="currentPage" prev-text="letzte" next-text="nächste" hide-goto-end-buttons/>
              </nav>
              <div class="mt-4" v-if="filteredCustomers && filteredCustomers && getGiveaway()">
                  <b-form-group class="mt-3" label="Anzahl Gewinner:" label-for="input-2">
                    <b-form-input
                      style="max-width: 80px"
                      v-model="getGiveaway().amount_winners"
                      :max="filteredCustomers.length"
                      disabled
                      size="sm"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                                            
                
              </div>

              <div>
                <b-button v-if="!getGiveaway().done" :disabled="getGiveaway().amount_winners == 0 || getGiveaway().amount_winners > filteredCustomers.length ? true : false " size="sm" variant="success" class="mb-2" @click="shuffleStart(getGiveaway().amount_winners)">
                    <b-icon icon="check" aria-hidden="true"></b-icon> {{ getGiveaway().done ? 'Gewinner erneut ermitteln' : 'Gewinner ermitteln'}}
                  </b-button>
              </div>
              <div>
                  <b-button v-if="getGiveaway().done" size="sm" variant="info" class="mb-2" @click="resetWinners()">
                    <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon> Gewinner zurücksetzen
                  </b-button>
              </div>
              <div>
                  <b-button v-if="getGiveaway().done" :disabled="amountWinners == 0 ? true : false" size="sm" variant="success" class="mb-2" @click="createCouponsModal = true">
                    <font-awesome-icon class="mr-1" :icon="['fas', 'paper-plane']"/> {{ getGiveaway().request_verification ? 'Teilnehmer benachrichtigen' : 'Gutscheincodes erstellen'}}
                  </b-button>  
              </div>
              <!-- <b-button size="sm" variant="info" class="mb-2" @click="refreshData(), shuffleDone = false">
                    <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon> neu laden
                  </b-button>   -->
              <div class="mt-5" v-if="filteredCustomers">
                <b-button size="sm" variant="danger" class="mb-2" @click="deleteAllModal = true">
                  <b-icon icon="trash" aria-hidden="true"></b-icon> Alle Teilnehmer Löschen
                </b-button>
              </div>
              
            </div>
          </b-row>
        </div>

        <div class="showcase-invert bg-white  m-2 mx-4 mt-5"  style="min-width: 95%" v-if="coupons">
          <div class="showcase-header py-4 linear-info text-white" style="position: relative">
            <h4 class="text-center m-0">Vorhandene Gutscheine ({{ coupons.length }})</h4>
          </div>
          <b-row class="m-0 justify-content-center">

            <div class="p-4 w-100">
              <b-table hover="hover" striped="striped" v-if="coupons.length > 0" borderless small="small" responsive :items="coupons" :fields="couponfields" :current-page="currentPageCoupons" per-page="10" @row-clicked="rowClicked">
              <template v-slot:cell(code)="data">
                <p class="m-0 mt-2">{{ data.item.code }}</p>
              </template>

              <template v-slot:cell(amount)="data">
                <p class="m-0 mt-2">{{ data.item.amount }}</p>
              </template>

              <template v-slot:cell(discount_type)="data">
                <p class="m-0 mt-2">
                  {{ data.item.discount_type == 'fixed_cart' ? 'Fester Warenkorb-Rabatt' : null }}
                  {{ data.item.discount_type == 'percent' ? 'Prozentualer Rabatt' : null }}
                </p>
              </template>

              <template v-slot:cell(description)="data">
                <p class="m-0 mt-2"><span v-html="data.item.description"></span></p>
                <!-- <p class="m-0 mt-2">{{ data.item.description }}</p> -->
              </template>

              <template v-slot:cell(usage_limit)="data">
                <p class="m-0 mt-2">{{ data.item.usage_count }}  / {{ data.item.usage_limit ? data.item.usage_limit : '&#x221E;'}}</p>
              </template>

              <template v-slot:cell(free_shipping)="data">
                <b-icon v-if="data.item.free_shipping === true" class="text-success h1 m-0 p-0" icon="check" aria-hidden="true"></b-icon>
                <b-icon v-if="!data.item.free_shipping" class="text-danger h1 m-0 p-0" icon="x" aria-hidden="true"></b-icon>
              </template> 

              <template v-slot:cell(email_restrictions)="data">
                <div v-if="data.item.email_restrictions.length == 0">
                  keine Beschränkung
                </div>
                <div v-else v-for="email in data.item.email_restrictions" :key="email.index">
                  {{ email }}
                </div>
              </template>

              <template v-slot:cell(date_expires)="data">
                <p class="m-0 mt-2">{{ data.item.date_expires | moment('DD.MM.YYYY') }}</p>
              </template>

              <template v-slot:cell(delete)="data">
                <div class="justify-content-center pt-2">
                  <b-button size="sm" variant="danger" class="ml-2 mb-2" @click="$store.dispatch('deleteCoupon', data.item.id )">
                    <b-icon v-if="!updating" icon="trash" aria-hidden="true"></b-icon>
                    <b-spinner v-if="updating" small label="Spinning"></b-spinner>
                  </b-button>
                </div>
              </template>
              </b-table>
              <nav>
                <b-pagination size="sm" :total-rows="coupons.length" per-page="10" v-model="currentPageCoupons" prev-text="letzte" next-text="nächste" hide-goto-end-buttons/>
              </nav>          
            </div>
          </b-row>
        </div>
      </div>
      
    </div>
    </b-row>

    <b-modal no-close-on-backdrop hide-footer size="lg" hide-header centered v-model="importModal" title="Datenimport aus Datei" v-if="parseCsv">
      <div class="bg-white p-3 rounded">
      <h4>Datenimport aus Datei</h4>
      <div v-if="!importStarted && actualImport == 0">
        <p class="m-0">wollen Sie {{ parseCsv.length }} Teilnehmer hinzufügen?</p>
        <div class="mb-4">
          <small >Dieser Vorgang kann einige Zeit in anspruch nehmen</small>
        </div>
      </div>
      <div v-if="importStarted && actualImport < max"  >
        <p class="m-0 mb-3">Import läuft, laden Sie diese Seite nicht neu!</p>
      </div>
      <div v-if="!importStarted && actualImport == max"  >
        <p class="m-0 mb-3">Import abgeschlossen!</p>
      </div>

      <div v-if="importStarted || actualImport > 0">
        <small>Gesamt:</small>
        <b-progress class="mb-2" :max="max" show-value>
          <b-progress-bar :value="actualImport" variant="info" :animated="importStarted"></b-progress-bar>
        </b-progress>
        <small>Erfolgreich:</small>
        <b-progress class="mb-2" :max="max" show-value>
          <b-progress-bar :value="ok" variant="success" :animated="importStarted"></b-progress-bar>
        </b-progress>
        <small>Fehlerhaft:</small>
        <b-progress class="mb-2" :max="max" show-value>
          <b-progress-bar :value="errors" variant="danger" :animated="importStarted"></b-progress-bar>
        </b-progress>
        <div v-if="errors > 0">
          <b-button size="sm" v-b-toggle.collapse-1 variant="danger">Fehler anzeigen</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
              
              <b-table :current-page="currentPageErrors" per-page="5" hover="hover" striped="striped" borderless small="small" fixed="fixed" responsive="sm" :items="errorLog">
              </b-table>
              <nav>
                <b-pagination size="sm" :total-rows="errorLog.length" per-page="5" v-model="currentPageErrors" prev-text="letzte" next-text="nächste" hide-goto-end-buttons/>
              </nav>
            </b-card>
          </b-collapse>
        </div>
      </div>
      <b-row class="m-0 justify-content-between">
      <b-button v-if="!importStarted && actualImport == 0" class="mt-4" style="background-color:  rgb(51,211,241); border: none !important; border-radius: 0" @click="triggerImport">Starten</b-button>
      <b-button v-if="!importStarted" class="mt-4" style="background-color:  rgb(51,211,241); border: none !important; border-radius: 0" @click="importModal = false, actualImport = 0">Schließen</b-button>

      </b-row>
      </div>
    </b-modal>

    <b-modal hide-footer size="lg" hide-header centered v-model="createCouponsModal" v-if="filteredCustomers && filteredCustomers">
      <div class="rounded bg-white p-3" v-if="getGiveaway()">
      <h4 v-if="getGiveaway().request_verification">{{ getGiveaway().notify_losers ? 'Teilnehmer' : 'Gewinner'}} per E-Mail informieren</h4>
      <h4 v-if="!getGiveaway().request_verification">Coupons erstellen und {{ getGiveaway().notify_losers ? 'Teilnehmer' : 'Gewinner'}} per E-Mail informieren</h4>
      
      <div>
        <p class="m-0">- Es haben <span class="text-success"> {{ filteredCustomers.filter(el => el.won).length }}</span> Teilnehmer gewonnen.</p>
      </div>

      <div >
        <p v-if="getGiveaway().notify_losers" class="m-0">- Es werden alle Teilnehmer benachrichtigt.</p>
        <p v-else class="m-0">- Es werden nur Gewinner benachrichtigt.</p>
        <p v-if="getGiveaway().request_verification" class="m-0">- Gewinner müssen Ihre E-Mail-Adresse bestätigen.</p>
        <p v-if="getGiveaway().request_verification" class="m-0">- Gewinner-Coupons werden erst nach Bestätigung erstellt.</p>
      </div>

      <b-button class="mt-2" size="sm" v-b-toggle.collapse-2 variant="info">Gewinner anzeigen</b-button>
      <b-collapse id="collapse-2" class="mt-2">
        <b-card>
          <b-table :current-page="currentPageWinners" per-page="10" hover="hover" striped="striped" :fields="winnerFields" borderless small="small" fixed="fixed" responsive="sm" :items="filteredCustomers.filter(el => el.won)">
          </b-table>
          <nav>
            <b-pagination size="sm" :total-rows="filteredCustomers.length" per-page="10" v-model="currentPageWinners" prev-text="letzte" next-text="nächste" hide-goto-end-buttons/>
          </nav>
        </b-card>
      </b-collapse>

      <div class="mt-4" v-if="getGiveaway()">
        <!-- <b-form >

          <b-form-group label="Code-Typ:" >
            <b-form-radio disabled required v-model="getGiveaway().voucher_winner.code_type" name="A" value="A">Vorname_Nachname_{{ v4.toString().substring(0,7) }}</b-form-radio>
            <b-form-radio disabled required v-model="getGiveaway().voucher_winner.code_type" name="A" value="B">E-Mail_{{ v4.toString().substring(0,7) }}</b-form-radio>
            <b-form-radio disabled required v-model="getGiveaway().voucher_winner.code_type" name="A" value="C">{{ v4.toString().substring(0,7) }}</b-form-radio>
          </b-form-group>


          <b-form-group
            label="Beschreibung"
            description="z.B.: Gewinnspiel XY"
          >
            <b-form-input
              v-model="getGiveaway().voucher_winner.description"
              type="text"
              required
              size="sm"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Gutscheintyp:" >
            <b-form-select
              v-model="getGiveaway().voucher_winner.discount_type"
              :options="discount_types"
              size="sm"
              required
              disabled
            ></b-form-select>
          </b-form-group>

          <b-form-group  label="Gutscheinbetrag">
            <b-form-input
              v-model="getGiveaway().voucher_winner.amount"
              type="number"
              required
              size="sm"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group>
              <b-form-checkbox disabled v-model="getGiveaway().voucher_winner.free_shipping">Gratis Lieferung</b-form-checkbox>
          </b-form-group>

        </b-form> -->

      <div v-if="actualCoupon >= 1 && filteredCustomers.length > 0">
        <small>Gesamt:</small>
        <b-progress class="mb-2" :max="shuffledCustomers.length" show-value>
          <b-progress-bar :value="actualCoupon" variant="info" :animated="creatingStarted"></b-progress-bar>
        </b-progress>
      </div>

      <b-row class="m-0 justify-content-between">
      <b-button v-if="!creatingStarted && actualCoupon == 0" class="mt-4" style="background-color:  rgb(51,211,241); border: none !important; border-radius: 0" @click="triggerCoupons()">Coupons erstellen und {{ getGiveaway().notify_losers ? 'Teilnehmer' : 'Gewinner'}} informieren</b-button>
      <b-button v-if="!creatingStarted" class="mt-4" style="background-color:  rgb(51,211,241); border: none !important; border-radius: 0" @click="createCouponsModal = false, actualCoupon = 0">Schließen</b-button>

      </b-row>
      </div>

      </div>
    </b-modal>

    <b-modal centered hide-footer v-model="deleteAllModal" hide-header>
      <div class="rounded p-3 bg-white"> 
        <h3 class="text-danger">Wollen Sie wirklich die gesamte Teilnehmerliste löschen?</h3>
        <h4 class="text-danger">Dies ist unwiderruflich!</h4>
        <b-row class="m-0 justify-content-between">
          <b-button variant="warning" class="mt-4"  @click="$store.dispatch('deleteAllCustomers', $route.params.id), deleteAllModal = false, shuffledCustomers = []">Ich bin mir sicher</b-button>
          <b-button variant="info" class="mt-4"  @click="deleteAllModal = false">Abbrechen</b-button>
        </b-row>
      </div>
      
    </b-modal>

  

  </div>
</template>

<script>

import coreData from '@/mixins/coreDataMixin'
import { jwtGetAdmin } from '@/mixins/jwtUtils'
// import draggable from "vuedraggable";
import { VueCsvImport } from 'vue-csv-import';
import axiosAuth from '@/shared/configs/axios-auth'
import { uuid } from 'vue-uuid';

export default {
  name: 'Giveaways',
  mixins: [coreData],
  data(){
    return {
      currentPageCoupons: 1,
      creatingStarted: false,
      actualCoupon: 0,
      uuid: uuid.v1(),
      v1: this.$uuid.v1(),
      // v3: this.$uuid.v3(),
      v4: this.$uuid.v4(),
      form: {},
      createCouponsModal: false,
      shuffleDone: false,
      amountWinners: 1,
      shuffledCustomers: [],
      getWinnerModal: false,
      termsAccepted: false,
      authRequired: false,
      newQuestion: {
        options: []
      },
      deleteAllModal: false,
      parseCsv: null,
      newCustomer: {
        firstname: "",
        lastname: "",
        email: ""
      },
      customerfields: [
        {key: 'firstname', label: 'Vorname', sortable: true},
        {key: 'lastname', label: 'Nachname', sortable: true},
        {key: 'email', label: 'E-Mail', sortable: true},
        {key: 'won', label: 'Gewinner', sortable: true},
        {key: 'notified', label: 'Benachrichtigt'},
        {key: 'verified', label: 'Bestätigt'},
        {key: 'on_time', label: 'On Time'},
        {key: 'delete', label: 'Entfernen'},
      ],
      winnerFields: [
        {key: 'firstname', label: 'Vorname', sortable: true},
        {key: 'lastname', label: 'Nachname', sortable: true},
        {key: 'email', label: 'E-Mail', sortable: true},
      ],
      couponfields: [
        {key: 'code', label: 'Code', sortable: true},
        {key: 'amount', label: 'Betrag', sortable: true},
        {key: 'discount_type', label: 'Gutscheintyp', sortable: true},
        {key: 'description', label: 'Beschreibung', sortable: true},
        {key: 'usage_limit', label: 'Limit', sortable: true},
        {key: 'free_shipping', label: 'Gratis Lieferung', sortable: true},
        {key: 'email_restrictions', label: 'E-Mails', sortable: true},
        {key: 'delete', label: 'Entfernen'},
        {key: 'date_expires', label: 'Ablaufdatum'}
      ],
      discount_types: [{value: 'percent', text: 'Prozentualer Nachlass/Rabatt'}, {value: 'fixed_cart', text: 'Fester Warenkorb-Rabatt'}],
      currentPageErrors: 1,
      actualImport: 0,
      importStarted: false,
      currentPage: 1,
      currentPageWinners: 1,
      ok: 0,
      errors: 0,
      perPage: 10,
      admin: false,
      stop: false,
      editAgenda: null,
      selected: null,
      usernameModal: false,
      username: "Admin",
      loading: true,
      message: "",
      importModal: false,
      errorLog: [],
      autoRefresh: null
    }
  },
  components: {
    VueCsvImport
  },
  computed:{
    filteredCustomers(){
      if(this.customers){
        return this.customers.filter(el => el.giveaway == this.$route.params.id)
      }

      return []
    },
    max(){
      if(this.parseCsv){
        return this.parseCsv.length
      }else{
        return 0
      }
    },

  },
  watch: {
    
    filteredCustomers(){
      if(this.filteredCustomers.length > 0){
        this.shuffledCustomers = this.filteredCustomers
      }
    },

    isAdmin(){
      this.refreshData()
    },

  },
  mounted(){
    console.log(this.$route)
    var admin = jwtGetAdmin(localStorage.getItem('token'))
    this.$store.commit('isAdmin', admin)
    if(admin){
      this.refreshData()
      this.$store.dispatch('checkLogin')

    }else{
      this.adminModal = true
    }
    if(!localStorage.getItem('username')){
      this.usernameModal = true
    }
    
    var vm = this;
    setTimeout(function(){ vm.loading = false }, 100);
  },
  methods: {
    resetWinners(){
      this.$store.dispatch('resetWinners', this.getGiveaway()._id)
      var updatedGivaway = {
        _id: this.getGiveaway(this.$route.params.id)._id,
        done: false
      }
      this.$store.dispatch('updateGiveaway', updatedGivaway)
    },
    updateGiveaway(giveaway) {
      this.$store.dispatch('updateGiveaway', giveaway)
    },
    getGiveaway(){
      return this.giveaways.find(el => el._id == this.$route.params.id)
    },
    shuffleStart(amount){
      var startLength = this.shuffledCustomers.length
      this.shuffledCustomers = this.shuffle(this.filteredCustomers)
      for (let index = 0; index < amount; index++) {
        this.shuffledCustomers[index].won = true
      }
      this.shuffledCustomers.splice(amount, startLength)

      this.shuffledCustomers.forEach(element => {
        element.won = true
        this.$store.dispatch('updateCustomer', element)
      });

      this.shuffleDone = true

      var updatedGivaway = {
        _id: this.getGiveaway(this.$route.params.id)._id,
        done: true
      }
      
      this.$store.dispatch('updateGiveaway', updatedGivaway)
      //this.$store.dispatch('getCustomers')
    },
    shuffle(a) {
      console.log('shuffling')
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
          j = Math.floor(Math.random() * (i + 1));
          x = a[i];
          a[i] = a[j];
          a[j] = x;
      }
      return a;
    },

    async triggerImport(){
      this.errorLog = []
      this.importStarted = true;
      this.actualImport = 0
      var vm = this;
      vm.ok = 0;
      vm.errors = 0;
      async function test () {
        for (var a of vm.parseCsv) {
          a.giveaway = vm.$route.params.id
          await postCustomer(a)
          vm.dummy = a;
          vm.actualImport += 1;
          if(vm.stop) return;
        }
        vm.importStarted = false;
        vm.$store.dispatch('getCustomers')
      }

      function postCustomer (a) {
        return new Promise((resolve) => {
            axiosAuth.post('/customers', a)
                .then(
                    res => {
                        vm.dummy = res
                        vm.ok += 1;
                        resolve()
                    }
                )
                .catch(
                error => {
                  console.log(error.response)
                    vm.errorLog.push({error: error.response.data, email: a.email, firstname: a.firstname, lastname: a.lastname})
                    vm.dummy = error.response
                    vm.errors += 1
                    resolve()
                }
                )
          });
      }
      test();
    },
    async triggerCoupons(){
      this.errorLog = []
      this.creatingStarted = true;
      this.actualCoupon = 0
      var vm = this;
      vm.ok = 0;
      vm.errors = 0;
      async function test () {
        for (var a of vm.filteredCustomers.filter(el => el.won)) {
          var payload = {
            emailtemplate: vm.getGiveaway().email_template_winner,
            firstname: a.firstname,
            lastname: a.lastname,
            email: a.email,
            discount_type: vm.getGiveaway().voucher_winner.discount_type,
            amount: vm.getGiveaway().voucher_winner.amount,
            individual_use: true,
            free_shipping : vm.getGiveaway().voucher_winner.free_shipping,
            description: vm.getGiveaway().voucher_winner.description,
            email_restrictions: [a.email],
            usage_limit: 1,
            date_expires: vm.getGiveaway().voucher_winner.date_expires,
            create_voucher: !vm.getGiveaway().request_verification,
            _id: a._id,
            amount_hours: vm.getGiveaway().amount_hours
          }

          if(vm.getGiveaway().voucher_winner.discount_type == 'percent'){
            payload.amount = payload.amount + '%'
          }else{
            payload.amount = payload.amount + '€'
          }

          switch (vm.getGiveaway().voucher_winner.code_type) {
            case 'A':
              payload.code = a.firstname + '_' + a.lastname + '_' + vm.$uuid.v1().substring(0,7)
              break;
            case 'B':
              payload.code = a.email + '_' + vm.$uuid.v1().substring(0,7)
              break;
            case 'C':
              payload.code = vm.$uuid.v1().substring(0,7)

              break;
            default:
              break;
          }
          
          if(!a.notified) await createCoupon(payload)
          
          vm.dummy = a;
          vm.actualCoupon += 1;
          if(vm.stop) return;
        }

      if(vm.getGiveaway().notify_losers){

        var coupon_data = {
          description: vm.getGiveaway().voucher_loser.description,
          code: vm.getGiveaway().voucher_loser.description,
          free_shipping : vm.getGiveaway().voucher_loser.free_shipping,
          discount_type: vm.getGiveaway().voucher_loser.discount_type,
          individual_use: true,
          date_expires: vm.getGiveaway().voucher_loser.date_expires,
          amount: vm.getGiveaway().voucher_loser.amount,
        }

        switch (vm.getGiveaway().voucher_loser.code_type) {
            // case 'A':
            //   payload.code = a.firstname + '_' + a.lastname + '_' + vm.$uuid.v1().substring(0,7)
            //   break;
            // case 'B':
            //   payload.code = a.email + '_' + vm.$uuid.v1().substring(0,7)
            //   break;
            case 'C':
              coupon_data.code = vm.$uuid.v1().substring(0,7)
              break;
            case 'D':
              coupon_data.code = vm.getGiveaway().voucher_loser.custom
              break;
            default:
              break;
          }

        vm.$store.dispatch('postCouponSingle', coupon_data)

        for (a of vm.filteredCustomers.filter(el => !el.won)) {
          payload = {
            emailtemplate: vm.getGiveaway().email_template_loser,
            firstname: a.firstname,
            lastname: a.lastname,
            email: a.email,
            usage_limit: 1,
            create_voucher: false,
            _id: a._id,
            description: vm.getGiveaway().voucher_loser.description,
            amount: vm.getGiveaway().voucher_loser.amount,
            amount_hours: vm.getGiveaway().amount_hours,
            date_expires: vm.getGiveaway().voucher_loser.date_expires,
            loser: true,
            code: coupon_data.code
          }

          if(vm.getGiveaway().voucher_loser.discount_type == 'percent'){
            payload.amount = payload.amount + '%'
          }else{
            payload.amount = payload.amount + '€'
          }
          
          if(!a.notified) await createCoupon(payload)

          vm.dummy = a;
          vm.actualCoupon += 1;
          if(vm.stop) return;
        }
      }


        vm.creatingStarted = false;
        vm.$store.dispatch('getCoupons')
        vm.$store.dispatch('getCustomers')
      }

      function createCoupon (a) {
        return new Promise((resolve) => {
            axiosAuth.post('/coupons', a)
                .then(
                    res => {
                        vm.dummy = res
                        vm.ok += 1;
                        resolve()
                    }
                )
                .catch(
                error => {
                  console.log(error.response)
                    vm.errorLog.push({error: error})
                    vm.dummy = error.response
                    vm.errors += 1
                    resolve()
                }
                )
          });
      }

      test();
    },
    startImport(){
      if(this.parseCsv){
        this.importModal = true
      }
    },
    rowClicked(){

    },

    deleteCustomer(id){
      this.$store.dispatch('deleteCustomer', id)
    },
    postCustomer(){
      this.newCustomer.giveaway = this.$route.params.id
      this.$store.dispatch('postCustomer', this.newCustomer)
      this.newCustomer = {}
    },

  }
}
</script>

<style lang="scss" scoped>
.outer {
  display: table;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white !important;
}

  .showcase-admin {
    width: 100%;
    max-width: 2000px;
    height: 850px;
    background-repeat: no-repeat;
    position: relative;
  }

  .custom-input {
    border-radius: 0 !important;
    border: 2px solid  rgb(51,211,241) !important;
  }

  .custom-input-chat {
    max-width: 350px !important;
    border-radius: 0 !important;
    border: none !important;
    background-color:  rgb(51,211,241) !important;
    color: white !important;
  }

  .custom-button {
    border-radius: 0 !important;
    border: none !important;
    color: white;
    background-color:  rgb(51,211,241) !important;
  }

  .quote-showcase {
    width: 670px;
    max-width: 90vw;
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: justify;
  }

  .quote {
     font-weight: 400 !important;
  }
  
  .ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border-radius: 20px;
  }

  .center {
    position: relative;
  }

  .center p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>