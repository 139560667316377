<template>
  <div class="home">
    <b-row class="m-0 justify-content-center">
    <div class="w-100 " style="max-width: 1920px">
      <b-row class="justify-content-between mx-3 mx-md-5 m-0 pb-5">
        <img @click="$router.push('/').catch(err => {})" style="cursor: pointer; max-width: 265px; max-height: 128px" alt="BGM Logo" src="../assets/Logo.png">
      </b-row>

<b-row class="justify-content-center text-center m-0 mt-0 mt-5 mt-md-0" >
        <div class="main-header mt-3 bg-white" style="position: relative" >
          
          <div class="overlay"></div>
          <h1 class="text-info center mt-3"><strong>Kontakt </strong>📞</h1>
        </div>
       
    </b-row>

    <b-row class="m-0 justify-content-center text-center m-0 mt-0 p-2" style="margin-top: 100px !important; padding-bottom: 150px !important" >
      <div style="max-width: 800px">
       
        <h1 class="mt-5 mb-3">Du willst reden?</h1>
        <h2 class="mt-5">Du kannst mich auf vielen Wegen erreichen!</h2>
        <h3 class="mt-4">Such dir einen aus:</h3>
        <b-row class="m-0 justify-content-center mt-5" :class="whiteMode ? 'text-white' : 'text-white'">
          <b-avatar variant="info" class="p-2 m-2 btn-custom" size="80px"><font-awesome-icon @click="windowOpen('http://m.me/alexander.paulus.92')" :class="whiteMode ? 'text-white' : 'text-white'" style="font-size: 50px; cursor: pointer" :icon="['fab', 'facebook-square']"/></b-avatar>
          <b-avatar variant="info" class="p-2 m-2 btn-custom" size="80px"><font-awesome-icon @click="windowOpen('https://wa.me/4915114904081')" :class="whiteMode ? 'text-white' : 'text-white'" style="font-size: 50px; cursor: pointer" :icon="['fab', 'whatsapp']"/></b-avatar>
          <b-avatar variant="info" class="p-2 m-2 btn-custom" size="80px"><a href="tel:+4915114904081"><font-awesome-icon :class="whiteMode ? 'text-white' : 'text-white'" style="font-size: 37px" :icon="['fas', 'phone']"/></a></b-avatar>
          <b-avatar variant="info" class="p-2 m-2 btn-custom" size="80px"><a href="mailto:info@ap-webdesign.de"><font-awesome-icon :class="whiteMode ? 'text-white' : 'text-white'" style="font-size: 37px" :icon="['fas', 'envelope']"/></a></b-avatar>
         </b-row>

      </div>
    </b-row>

    </div>
    </b-row>
      
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Kontakt',
  data(){
    return {
      whiteMode: true
    }
  },
  components: {

  },
  methods: {
    windowOpen(link){
        window.open(link, "_blank")
    },
  }
}
</script>