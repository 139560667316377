import Vue from 'vue'
import VueRouter from 'vue-router'
import MainContainer from '../components/MainContainer'
import Dashboard from '../views/Dashboard'
import Orders from '../views/Orders'
import Impressum from '../views/Impressum'
import Datenschutz from '../views/Datenschutz'
// import Nutzungshinweise from '../views/Nutzungshinweise'
import Kontakt from '../views/Kontakt'
import Giveaways from '../views/Giveaways'
import Giveaway from '../views/Giveaway'
import Confirm from '../views/Confirm'
import Login from '../views/Login'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainContainer,
    children: [
      {
        path: '',
        name: 'HomeMain',
        component: Dashboard
      },
      {
        path: '/giveaways',
        name: 'Giveaways',
        component: Giveaways
      },
      {
        path: '/giveaways/:id',
        name: 'Giveaways',
        component: Giveaway
      },
      {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
      },
      {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: Datenschutz
      },
      {
        path: '/orders',
        name: 'Bestellungen',
        component: Orders
      },
      {
        path: '/kontakt',
        name: 'Kontakt',
        component: Kontakt
      },
      {
        path: '/confirm/:token',
        name: 'Confirm',
        component: Confirm
      },
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      // {
      //   path: '/admin',
      //   name: 'Admin',
      //   component: Admin
      // },
      {
        path: '*',
        name: '404',
        component: Dashboard
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
