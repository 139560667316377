<template>
  <div>
    <Navbar/>
      <div style="scroll-behavior: smooth; ">
      <main>
        <div class="" style="margin-top: 30px; margin-bottom: 40px" >
          <transition name="fade" mode="out-in">
            <router-view ></router-view>
          </transition>
        </div>
      </main>
    </div>

    <Footer/>

    <!-- <b-modal no-close-on-backdrop hide-footer centered hide-header v-model="adminModal">
      <div class="rounded bg-white p-3">
      <p class="mb-4">Admin Login</p>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="E-Mail-Adresse" label-for="input-2">
          <b-form-input
            :state="authError != null ? false: null"
            id="input-1"
            v-model="email"
            required
            placeholder="E-Mail-Adresse eingeben..."
          ></b-form-input>
          <b-form-invalid-feedback :state="authError != null ? false: null">
          {{authMessage}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-2" label="Passwort" label-for="input-2">
          <b-form-input
            :state="authError != null ? false: null"
            id="input-2"
            v-model="password"
            required
            type="password"
            placeholder="Passwort eingeben"
          ></b-form-input>
          <b-form-invalid-feedback :state="authError != null ? false: null">
            {{authMessage}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row class="m-0 justify-content-between">
          <b-button type="submit" variant="primary">Einloggen <b-spinner v-if="updating" small label="Spinning"></b-spinner></b-button>
        </b-row>
        
      </b-form>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import Footer from '../components/Footer'
import Navbar from '@/components/Navbar'
import coreData from '@/mixins/coreDataMixin'
import { jwtGetAdmin } from '@/mixins/jwtUtils'

export default {
  name: 'MainContainer',
  mixins: [coreData],
  components: {
    Footer,
    Navbar
  },
  props: {

  },
  data(){
    return {
      adminModal: false,
      email: "",
      password: "",
    }
  },
  mounted(){

    var admin = jwtGetAdmin(localStorage.getItem('token'))
    this.$store.commit('isAdmin', admin)
    if(admin){

      this.adminModal = false
      this.refreshData()
      this.$store.dispatch('checkLogin')

    }else{
      this.adminModal = true
    }
    
    var vm = this;
    setTimeout(function(){ vm.loading = false }, 100);
  },
  methods: {
    scrollToTop(){
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    onSubmit(){
      this.$store.dispatch('login', {email: this.email, password: this.password})
    },
  }
}
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .rotating
    {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
    }
  .disabled {
    opacity: .5;
  }

.hover {
  cursor: pointer;
}
.showcase-lite {
    border-radius: 8px;
    border-left-width: 4px;
    border-left-style: solid;
  //  box-shadow: -1px -1px 1px rgba(255,255,255,0.05), 1px 1px 2px rgba(0,0,0, .5) !important
  }

  .dropshadow {
    //border-radius: 10px;
    //border: none !important;
    //box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94,104,121, .288) !important
    box-shadow: 1px 1px 1px rgba(255,255,255,0.05), 1px 1px 5px rgba(0,0,0, .5) !important
  }

  td {
    padding: 0 !important
  }

  .showcase-light{
    cursor: pointer;
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .main-header{
    box-shadow: 3px -5px 15px rgba(255, 255, 255, 0.05), 3px 2px 15px rgba(0,0,0, .5) !important;
    padding-top: auto;
    text-align: center;
    position: relative;;
    height: 300px;
    width: 100%;
    // background: url('../assets/appdev.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

.pulse {
  cursor: pointer;
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(29, 207, 38, 2);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(29, 207, 38, 1);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(29, 207, 38, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(29, 207, 38, 2);
    box-shadow: 0 0 0 0 rgba(29, 207, 38, 1);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(29, 207, 38, 1);
      box-shadow: 0 0 0 10px rgba(29, 207, 38, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(29, 207, 38, 0);
      box-shadow: 0 0 0 0 rgba(29, 207, 38, 0);
  }
}
  .bg-dark {
    transition: .3s
  }
  .bg-white {
    transition: .3s
  }
  .main-header-container{
    box-shadow: inset -5px -5px 5px rgba(255,255,255,0.05), inset 2px 2px 5px rgba(0,0,0, .5) !important;
    padding-top: auto;
    text-align: center;
    position:relative;
    height: 335px;
    width: 100%;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: .5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.rotate-leave-active,
.rotate-enter-active {
  transition: .5s;
}
.rotate-enter {
  transform: rotate(-180deg);
}
.rotate-leave-to {
  transform: rotate(180deg);
}

.slideIn-leave-active,
.slideIn-enter-active {
  transition: .3s;
}
.slideIn-enter {
  transform: translate(100%, 0);
}
.slideIn-leave-to {
  transform: translate(100%, 0);
}

.slideLe-leave-active,
.slideLe-enter-active {
  transition: .3s;
}
.slideLe-enter {
  transform: translate(-100%, 0);
}
.slideLe-leave-to {
  transform: translate(-100%, 0);
}

.slideRi-leave-active,
.slideRi-enter-active {
  transition: .3s;
}
.slideRi-enter {
  transform: translate(100%, 0);
}
.slideRi-leave-to {
  transform: translate(100%, 0);
}

.sliderev-leave-active,
.sliderev-enter-active {
  transition: .5s;
}
.sliderev-enter {
  transform: translate(-100%, 0);
}
.sliderev-leave-to {
  transform: translate(100%, 0);
}

  .neumorphism {
    //border-radius: 10px;
    border: none !important;
    //box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94,104,121, .288) !important
    box-shadow: -2px -2px 5px rgba(255,255,255,0.05), 2px 2px 5px rgba(0,0,0, .5) !important
  }
  
  .neumorphism-1 {
    //border-radius: 10px;
    border: none !important;
    //box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94,104,121, .288) !important
    box-shadow: inset -5px -5px 5px rgba(255,255,255,0.05), inset 2px 2px 5px rgba(0,0,0, .5) !important
  }

  .inner {
    overflow: auto;
    margin-top: -2px;
    margin-left: -2px;
    position: absolute;
    background: #17e6df;
    padding: 1em;
    border-radius: 10px;
    width: 225px;
    height: 255px;
    clip-path: circle(7% at 85% 12%);
    transition: all .5s ease-in-out;
    cursor: pointer;

    &:hover {
        clip-path: circle(75%);
        background: #666666;
    }

    h4 {
        color: #17e6df !important;
        margin: 0;
    }

    p {
        color: white;
        font-size: .8rem;
        
    }

    

    span {
        float: right;
        color: white;
        font-weight: bold;
        transition: color .5s;
        position: relative;
        margin-right: 8%;
        margin-top: 2%;
    }
    &:hover span {
        color: rgba(255,255,255,0)
    }
    &:hover h4 {
        color: white !important
    }
}

  .custom-nav {
    cursor: pointer;
    .focus {
      outline: 0;
    }
  }

  .custom-nav:hover{
    color: #17e6df !important;
  }

  .modal-body {
    background-color: rgba(0,0,0,.0001) !important;
    border: none;
  }
  .modal-content {
    background-color: rgba(0,0,0,.0001) !important;
    border: none;
  }

  .showcase {
    padding: 20px;
    border-radius: 10px;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: inset -5px -5px 5px rgba(255,255,255,0.05), inset 2px 2px 5px rgba(0,0,0, .5) !important
  }

  .showcase-invert {
    transition: all .5s;
    padding: 15px;
    border-radius: 5px;
    border: none;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  }

  .brand-header {
    box-shadow: 5px 5px 5px rgba(255,255,255,0.05), 2px 2px 5px rgba(0,0,0, .5) !important;
    padding: 10px;
    border-radius: 5px;
    background: linear-gradient(60deg,#26c6da,#00acc1);
    &:hover {
        transform: translate(0, -10px);
    }
  }

  .showcase-header {
    transition: all .2s;
    margin: -30px 0px 10px;
    box-shadow: 0 12px 20px -10px rgba(104, 101, 101, 0.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(104, 101, 101,.2);
    -webkit-box-shadow: 0 12px 20px -10px rgba(104, 101, 101,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(104, 101, 101,.2);
    padding: 10px;
    border-radius: 5px;
    animation-duration: 1s;
    &:hover {
        //transform: translate(0, -60px);
    }
  }

  

  .linear-danger {
    background: linear-gradient(60deg,#ef5350,#e53935);
  }

  .linear-info {
    background: linear-gradient(60deg,#26c6da,#00acc1);
  }

  .linear-success {
    background: linear-gradient(60deg,#66bb6a,#43a047);
  }

  .linear-dark {
    background: linear-gradient(60deg,#343a40,rgb(61, 59, 59));
  }

  .linear-warning {
    background: rgb(255,193,7);
    background: linear-gradient(90deg, rgba(255,193,7,1) 0%, rgba(253,255,51,1) 100%);
  }

  .linear-primary {
    background: linear-gradient(60deg,#00acc1,#1755da);
  }



  .hover-expand{
    transform: scale(1.0);
    transition: all .3s ease-in-out;
  }

  .hover-expand:hover {
    transform: scale(1.05);
    transition: all .3s ease-in-out;
    z-index: 1000 !important;
  }

  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-custom {
    border-radius: 10px !important;
    border: none !important;
    //box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94,104,121, .288) !important
    box-shadow: -2px -2px 5px rgba(255,255,255,0.05), 2px 2px 5px rgba(0,0,0, .5) !important
  }

  .neumorphism {
    //border-radius: 10px;
    border: none !important;
    //box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94,104,121, .288) !important
    box-shadow: -2px -2px 5px rgba(255,255,255,0.05), 2px 2px 5px rgba(0,0,0, .5) !important
  }
</style>