import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        chat: [],
        chatId: null,
        lastMessage: null,
        connection: null,
        chatMessages: null,
        autoscroll: true,
        autoscroll2: true,
        connected: false,
        wsConnection: null,
    }
}

const state = getDefaultState()

const getters = {
    chat: state => {
        return state.chat
    },
    chatId: state => {
        return state.chatId
    },
    lastMessage: state => {
        return state.lastMessage
    },
    connection: state => {
        return state.connection
    },
    connected: state => {
        return state.connected
    },
    chatMessages: state => {
        return state.chatMessages
    },
    autoscroll: state => {
        return state.autoscroll
    },
    autoscroll2: state => {
        return state.autoscroll2
    },
    wsConnection: state => {
        return state.wsConnection
    },
}

const mutations = {
    chat (state, payload) {
        state.chat = payload
    },
    chatId (state, payload) {
        state.chatId = payload
    },
    lastMessage (state, payload) {
        state.lastMessage = payload
    },
    connection (state, payload) {
        state.connection = payload
    },
    connected (state, payload) {
        state.connected = payload
    },
    chatMessages (state, payload) {
        state.chatMessages = payload
    },
    autoscroll (state, payload) {
        state.autoscroll = payload
    },
    autoscroll2 (state, payload) {
        state.autoscroll2 = payload
    },
    wsConnection (state, payload) {
        state.wsConnection = payload
    },
}

const actions = {
    getChats: ({commit}, payload) =>{
        commit('updating', true)
        axiosAuth.get('/chats/' + payload._id)
        .then(
            res => {
                commit('updating', false)
                commit('chat', res.data)
            }
          )
          .catch(
            () => {
              commit('updating', false)
            }
          )
    },
    deleteMessage:({commit}, payload) => {
        axiosAuth.post('/deletechat/'+ payload._id)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
    }
  
}

export default {
    state,
    getters,
    mutations,
    actions
}