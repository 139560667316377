<template>
  <div class="home" style="padding-top: 90px; margin-bottom: 150px" >
    <b-row class="m-0 justify-content-center">
      <img src="https://avydan.appfreak.de/wp-content/uploads/2020/10/AvyDan_black_normal.png" alt="Media-lf Logo"/>
    </b-row>
    
    <b-modal no-close-on-backdrop hide-footer centered hide-header v-model="adminModal">
      <div class="rounded bg-white p-3">
      <p class="mb-4">Admin Login</p>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="E-Mail-Adresse" label-for="input-2">
          <b-form-input
            :state="authError != null ? false: null"
            id="input-1"
            v-model="email"
            required
            placeholder="E-Mail-Adresse eingeben..."
          ></b-form-input>
          <b-form-invalid-feedback :state="authError != null ? false: null">
          {{authMessage}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-2" label="Passwort" label-for="input-2">
          <b-form-input
            :state="authError != null ? false: null"
            id="input-2"
            v-model="password"
            required
            type="password"
            placeholder="Passwort eingeben"
          ></b-form-input>
          <b-form-invalid-feedback :state="authError != null ? false: null">
            {{authMessage}}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row class="m-0 justify-content-between">
          <b-button type="submit" variant="primary">Einloggen <b-spinner v-if="updating" small label="Spinning"></b-spinner></b-button>
        </b-row>
        
      </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>

import coreData from '@/mixins/coreDataMixin'
import { jwtGetAdmin } from '@/mixins/jwtUtils'

export default {
  name: 'Confirm',
  mixins: [coreData],
  data(){
    return {
      adminModal: false,
      email: "",
      password: "",
    }
  },
  components: {

  },
  computed:{
    verificationMessage(){
      return this.$store.getters.verificationMessage
    }
  },
  watch: {
  },
  mounted(){

    var admin = jwtGetAdmin(localStorage.getItem('token'))
    this.$store.commit('isAdmin', admin)
    if(admin){

      this.adminModal = false
      this.refreshData()
      this.$store.dispatch('checkLogin')

    }else{
      this.adminModal = true
    }
    
    var vm = this;
    setTimeout(function(){ vm.loading = false }, 100);
  },
  methods: {
    scrollToTop(){
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    onSubmit(){
      this.$store.dispatch('login', {email: this.email, password: this.password})
    },
  }
}
</script>
