import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { store } from '@/store'
import Vuex from 'vuex'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VScrollLock from 'v-scroll-lock'
import UUID from 'vue-uuid'

const moment = require('moment')
require('moment/locale/de')
 
Vue.use(require('vue-moment'), {
    moment
})

import VueQuillEditor from 'vue-quill-editor'
 
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor, /* { default global options } */)

  
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCogs, faTrophy, faFileAlt, faUtensils, faChartLine, faShoppingCart, faEnvelope, faPaperPlane, faStar, faSortDown, faSortUp, faCircle, faBookmark, faComment, faPhone} from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitter, faWhatsapp, faInstagram, faWordpress} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCogs, faTrophy, faFileAlt, faUtensils, faChartLine, faShoppingCart, faEnvelope, faFacebookSquare, faPaperPlane, faTwitter, faWhatsapp, faSortUp, faSortDown, faStar, faCircle, faBookmark, faComment, faInstagram, faWordpress, faPhone)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VScrollLock)
Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(UUID);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  console.log(to)
  if(to.path == '/nutzungshinweise'){
    next()
  }else if(to.name == 'Confirm'){
    next()
  }else if(to.path == '/kontakt'){
    next()
  }else if(to.path == '/datenschutz'){
    next()
  }else if(to.path == '/impressum'){
    next()
  }else{
    if((!localStorage.getItem('token')) && to.path != `/login`){
      console.log('NOPE')
      next(`/login`)

    }else{
      next()
    }
  }
})
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
