    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            stream: null
        }
    }
    
    const state = getDefaultState()

    const getters = {
        stream: state => {
            return state.stream
        }
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        stream: (state, payload) => {
            state.stream = payload
        }
    }

    const actions = {
       
        getStream: ({commit, dispatch}) =>{
            commit('updating', true)
            axiosAuth.get('/stream')
            .then(
                res => {
                    commit('updating', false)
                    commit('stream', res.data)
                }
              )
              .catch(
                error => {
                  commit('updating', false)
                    if(error.response){
                        if(error.response.status == 401) dispatch('logout')
                    }
                }
              )
        },

        postStream ({commit, dispatch}, payload) {
            commit('updating', true)
            axiosAuth.post('/stream', payload)
                .then(
                    res => {
                        commit('trash', res)
                        commit('updating', false)
                        dispatch('getStream')
                    }
                )
                .catch(
                () => {
                    commit('updating', false)
                }
                )
        },

        updateStream: ({commit, dispatch}, payload) => {
            commit('updating', true)
            axiosAuth.patch('/stream/'+ payload._id, payload)
            .then(
                res => {
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getStream')
                }
              )
            .catch(
                () => {
                    commit('updating', false)
                }
            ) 
        },

        deleteStream: ({commit}, payload) => {
            commit('updating', true)
            axiosAuth.delete('/stream/'+ payload._id)
            .then(
                res => {
                    commit('trash', res)
                    commit('updating', false)
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }