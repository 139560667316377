<template>
  <div class="shadow" v-if="$route.name != 'Confirm' && loggedIn()">
    <b-navbar type="light" class="shadow bg-white" fixed="top" toggleable="lg" style="z-index: 1000">
      <b-navbar-brand class="custom-nav" @click="$router.push('/').catch(err => {})" > 
        <div class="mt-1 ml-2">
            <img class="p-0" style="width: 200px; cursor: pointer" src="@/assets/Logo.png" alt="Appfreak Logo"/> 
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="mt-2 ml-auto">
          <b-nav-item :active="$route.name == 'Dashboard'" @click="$router.push('/').catch(err => {})"><font-awesome-icon icon="tachometer-alt" /> Dashboard</b-nav-item>
          <b-nav-item style="position: relative" :active="$route.name == 'Bestellungen'" @click="$router.push('/orders').catch(err => {})"><font-awesome-icon  icon="shopping-cart" /> Bestellungen </b-nav-item>
          <!-- <b-nav-item :active="$route.name == 'Statistiken'" @click="$router.push('/statistics').catch(err => {})"><font-awesome-icon icon="chart-line" /> Auswertungen</b-nav-item> -->
          <b-nav-item :active="$route.name == 'Giveaways'" @click="$router.push('/giveaways').catch(err => {})"><font-awesome-icon icon="trophy" /> Gewinnspiele</b-nav-item>
          <b-nav-item-dropdown right class="ml-0 ml-md-4">

            <template v-slot:button-content>
              <em><font-awesome-icon icon="cogs" /> Avy&Dan</em>
            </template>

            <!-- <b-dropdown-item  @click="$router.push('/me').catch(err => {})">Einstellungen</b-dropdown-item>
            <hr> -->
            <b-dropdown-item @click="$store.dispatch('logout')">Abmelden</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
      
    </b-navbar>
  </div>
</template>

<script>
import coreData from '@/mixins/coreDataMixin'
// import { mixin as clickaway } from 'vue-clickaway';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTachometerAlt, faChartBar, faUserFriends, faClone, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
// import Message from './Message'
var audio = new Audio('sounds/bell.mp3')

library.add(faTachometerAlt, faChartBar, faUserFriends, faClone, faUser)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: 'Navbar',
  mixins: [coreData],
  components: {
    // Message
  },
  props: {

  },
  data(){
    return {
      sideBarMobile: false,
      popOverMobile: false,
      popOver: false,
      checked: false,
      sideBar: false,
    }
  },
  created() {
   
    var darkMode = localStorage.getItem('darkMode')
    if(darkMode != null){
      if(darkMode == 'true' || darkMode == true){
        this.$store.commit('darkMode', false)
      }else{
        this.$store.commit('darkMode', true)
      }
      
      this.checked = darkMode
    }
    window.addEventListener('keydown', (e) => {
      if(e.key == 'Escape'){
        // this.$router.push('/home').catch(err => {console.log(err.err)})
        this.$router.push('/home').catch(err => {console.log(err.e)})
      }

      if (e.key == 'm' && e.ctrlKey) {
        this.sideBar = !this.sideBar
      }

      if (e.key == 'p' && e.ctrlKey) {
        this.popOver = !this.popOver
      }
    });

  },
  watch: {
    darkMode(){
      this.checked = !this.darkMode
      localStorage.setItem('darkMode', !this.darkMode)
    }
  },
  computed: {
    avatarClass(){
      var avatarClass = ''
      if(this.updating){
        avatarClass += 'rotating '
      }
      if(this.darkMode){
        avatarClass += 'bg-white text-info'
      }else{
        avatarClass += 'bg-dark text-info'
      }
      return avatarClass
    },
    darkMode(){
      return this.$store.getters.darkMode
    },
    popLogin(){
      return this.$store.getters.popLogin
    },
    items(){
      return  [
          {
            text: 'Admin',
            href: '#'
          },
          {
            text: 'Manage',
            href: '#'
          },
          {
            text: 'Library',
            active: true
          }
        ]
    }
  },
  mounted(){
    if(this.darkMode){
      this.checked = false
    }
    console.log(this.$router)
  },
  methods:{
    makeToast(append = false) {
        this.toastCount++
        this.$bvToast.toast(`Eine neue Bestellung ist eingetroffen!`, {
          title: 'Neue Bestellung!',
          autoHideDelay: 5000,
          appendToast: append
        })
        audio.play()
      },
    playSound(){
      audio.play()
    },
    changeTheme(){
      this.$store.commit('darkMode', this.checked)
    },
    toggleProfile(){
      if(this.popOver){
        this.popOver = false
      }
    },
    toggleSidebar(){
      if(this.sideBar){
        this.sideBar = false
      }
    },
    toggleProfileMobile(){
      if(this.popOverMobile){
        this.popOverMobile = false
      }
    },
    toggleSidebarMobile(){
      if(this.sideBarMobile){
        this.sideBarMobile = false
      }
    }
  }
}
</script>

<style>
  .modal-body {
    background-color: rgba(0,0,0,.0001) !important;
    border: none !important;
  }
  .modal-content {
    background-color: rgba(0,0,0,.0001) !important;
    border: none !important;
  }
</style>