<template>
  <b-navbar class="p-0" v-if="$route.name != 'Confirm' && loggedIn()">

    <div class="shadow d-none d-lg-flex  pt-3 bg-info" style="z-index: 1000; color: white; position: fixed; bottom: 0; left: 0; right: 0">
      <b-row class="m-0 justify-content-between w-100">
        <div class="ml-2">
        </div>
        <div>
          <b-row class="m-0 justify-content-end" >
            <a class="custom-link text-white" @click="$router.push('/impressum').catch(err => {})">Impressum</a>
            <a class="custom-link ml-5 text-white" @click="$router.push('/datenschutz').catch(err => {})">Datenschutz</a>
             <a class="custom-link ml-5 mr-5 text-white" @click="$router.push('/kontakt').catch(err => {})">Kontakt</a>
            <b-icon @click="scrollToTop" icon="chevron-up" class="mr-5 h2 pb-2" style="cursor: pointer"></b-icon>
          </b-row>
        </div>
      </b-row> 
    </div>
    <div class="pt-3 d-lg-none w-100" style="background-color: #585857; color: white;">
      <b-row class="m-0 justify-content-center">
        <a class="custom-link m-2 text-white" @click="$router.push('/impressum').catch(err => {})">Impressum</a>
        <a class="custom-link m-2 text-white" @click="$router.push('/datenschutz').catch(err => {})">Datenschutz</a>
        <a class="custom-link m-2 text-white" @click="$router.push('/kontakt').catch(err => {})">Kontakt</a>
      </b-row>
      <b-row class="m-0 justify-content-center">
        <b-icon @click="scrollToTop" icon="chevron-compact-up" class="mx-3 h2" style="cursor: pointer"></b-icon>
      </b-row>
      <b-row class="m-0 my-2 justify-content-center">
      </b-row>
    </div>
  </b-navbar>
</template>

<script>

import coreData from '@/mixins/coreDataMixin'

export default {
  name: 'Footer',
  mixins: [coreData],
  props: {

  },
  methods: {
    scrollToTop(){
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  }
}
</script>

<style scoped lang="scss">
  .custom-link{
    cursor: pointer;
    &:hover {
        color: #ED6D43 !important;
    }
  }
</style>
