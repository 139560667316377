<template>
  <div class="home" style="padding-top: 90px; margin-bottom: 150px" >
    <b-row class="m-0 justify-content-center" v-if="isAdmin">
    <div class="w-100" style="max-width: 1700px">
 
      <b-row class="justify-content-center m-0 mb-5">
        <h1 class="text-secondary">Avy&Dan Gewinnspiele</h1>        
      </b-row>
      
      <div>

        <div class="showcase-invert bg-white  m-2 mx-4"  style="min-width: 95%" v-if="giveaways.length > 0">
          <div class="showcase-header py-4 linear-info text-white" style="position: relative" >
            <h4 class="text-center m-0">Gewinnspiele</h4>
          </div>
          <b-row class="m-0 justify-content-center">
            <b-col cols="12" style="w-100" v-for="(giveaway) in giveaways" :key="giveaway.index">
              <div class="showcase-invert bg-white  m-2 mt-4"  style="min-width: 95%" v-if="giveaways.length > 0">

                <div class="showcase-header p-2 linear-info text-white hover" style="position: relative" @click="$router.push('/giveaways/' + giveaway._id).catch(err => {})">
                  <h5 class="text-center m-0">{{giveaway.title}}</h5>
                </div>
              </div>
              
            </b-col>
          </b-row>
        </div>

        <div class="showcase-invert bg-white m-2 mx-4 mt-5"  style="min-width: 95%" >
          <div class="showcase-header py-4 linear-info text-white" style="position: relative" v-b-toggle.collapse-create-giveaway>
            <h4 class="text-center m-0 text-white">Gewinnspiel erstellen</h4>
          </div>
          <b-collapse id="collapse-create-giveaway">
            <b-row class="m-0 justify-content-center">
              <div class="mt-3 w-100">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <b-row class="m-0 justify-content-start">
                    <b-col cols="12" md="8" class="mb-5">
                      <p class="mt-4 m-0 text-secondary"><strong>Einstellungen Gewinnspiel</strong></p>
                      <hr class="mb-2 mt-0">
                      <b-form-group
                        label="Gewinnspieltitel:"
                        description="z.B.: Avy&Dan Gewinnspiel 2021"
                      >
                        <b-form-input
                          v-model="form.title"
                          type="text"
                          placeholder="Titel eingeben..."
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Anzahl mögliche Gewinner:" >
                        <b-form-input
                          v-model="form.amount_winners"
                          type="number"
                          step="1"
                          min="1"
                          placeholder="Anzahl eingeben..."
                          required
                        ></b-form-input>
                      </b-form-group>

                      <small class="mt-4 m-0 text-secondary">Einstellungen Gewinner</small>
                      <hr class="mb-2 mt-0">

                      <b-form-group :description="'Gewinner müssen innerhalb von ' + (form.amount_hours ? form.amount_hours : 'X') + ' Stunden die E-Mail bestätigen'">
                        <b-form-checkbox v-model="form.request_verification">Gewinner muss E-Mail bestätigen</b-form-checkbox>
                      </b-form-group>

                      <b-form-group class="mx-4" label="Anzahl Stunden für die E-Mail-Bestätigung:" v-if="form.request_verification">
                        <b-form-input
                          v-model="form.amount_hours"
                          type="number"
                          step="1"
                          min="1"
                          placeholder="Anzahl eingeben..."
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="E-Mail-Template Gewinner:" >
                        <b-form-select
                          id="input-3"
                          v-model="form.email_template_winner"
                          :options="templatesOptions"
                          required
                        ></b-form-select>
                      </b-form-group>

                      <small class="mt-4 m-0 text-secondary">Einstellungen Verlierer</small>
                      <hr class="mb-2 mt-0">

                      <b-form-group class="">
                        <b-form-checkbox v-model="form.notify_losers">Verlierer werden benachrichtigt</b-form-checkbox>
                      </b-form-group>

                      <b-form-group class="mx-4" label="E-Mail-Template Verlierer:" v-if="form.notify_losers">
                        <b-form-select
                          id="input-3"
                          v-model="form.email_template_loser"
                          :options="templatesOptions"
                          required
                        ></b-form-select>
                      </b-form-group>
                    </b-col>


                    <b-col cols="12" md="5">
                      <p class="mt-4 m-0 text-secondary"><strong>Einstellungen Gewinnergutschein</strong></p>
                      <hr class="mb-2 mt-0">
                      <b-form-group label="Code-Typ:" >
                        <b-form-radio required v-model="form.voucher_winner.code_type" name="A" value="A">Vorname_Nachname_{{ v4.toString().substring(0,7) }}</b-form-radio>
                        <b-form-radio required v-model="form.voucher_winner.code_type" name="A" value="B">E-Mail_{{ v4.toString().substring(0,7) }}</b-form-radio>
                        <b-form-radio required v-model="form.voucher_winner.code_type" name="A" value="C">{{ v4.toString().substring(0,7) }}</b-form-radio>
                      </b-form-group>


                      <b-form-group
                        label="Beschreibung"
                        description="z.B.: Gewinnspiel XY"
                      >
                        <b-form-input
                          v-model="form.voucher_winner.description"
                          type="text"
                          required
                          size="sm"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Gutscheintyp:" >
                        <b-form-select
                          v-model="form.voucher_winner.discount_type"
                          :options="discount_types"
                          size="sm"
                          required
                        ></b-form-select>
                      </b-form-group>

                      <b-form-group  label="Gutscheinbetrag">
                        <b-form-input
                          v-model="form.voucher_winner.amount"
                          type="number"
                          required
                          size="sm"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group>
                          <b-form-checkbox v-model="form.voucher_winner.free_shipping">Gratis Lieferung</b-form-checkbox>
                      </b-form-group>
                      <p class="mt-4 m-0 text-secondary"><strong>Hinweis: Es wird pro Gewinner JEWEILS ein Gutschein angelegt!</strong></p>
                      <hr class="mb-2 mt-0">
                    </b-col>

                    <b-col cols="0" md="1"></b-col>

                    <b-col cols="12" md="5" v-if="form.notify_losers">
                      <p class="mt-4 m-0 text-secondary"><strong>Einstellungen Verlierergutschein</strong></p>
                      <hr class="mb-2 mt-0">
                      <b-form-group label="Code-Typ:" >
                        <b-form-radio required v-model="form.voucher_loser.code_type" name="B" value="A">Vorname_Nachname_{{ v4.toString().substring(0,7) }}</b-form-radio>
                        <b-form-radio required v-model="form.voucher_loser.code_type" name="B" value="B">E-Mail_{{ v4.toString().substring(0,7) }}</b-form-radio>
                        <b-form-radio required v-model="form.voucher_loser.code_type" name="B" value="C">{{ v4.toString().substring(0,7) }}</b-form-radio>
                        <b-form-radio required v-model="form.voucher_loser.code_type" name="B" value="D">
                          <b-form-input
                            style="width: 150%"
                            id="input-1"
                            v-model="form.voucher_loser.custom"
                            type="text"
                            placeholder="Eigenen Text eintragen..."
                            :required="form.voucher_loser.code_type == 'D'"
                            size="sm"
                          ></b-form-input>
                        </b-form-radio>
                      </b-form-group>


                      <b-form-group
                        label="Beschreibung"
                        description="z.B.: Gewinnspiel XY"
                      >
                        <b-form-input
                          v-model="form.voucher_loser.description"
                          type="text"
                          required
                          size="sm"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Gutscheintyp:" >
                        <b-form-select
                          v-model="form.voucher_loser.discount_type"
                          :options="discount_types"
                          size="sm"
                          required
                        ></b-form-select>
                      </b-form-group>

                      <b-form-group  label="Gutscheinbetrag">
                        <b-form-input
                          v-model="form.voucher_loser.amount"
                          type="number"
                          required
                          size="sm"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group>
                          <b-form-checkbox v-model="form.voucher_loser.free_shipping">Gratis Lieferung</b-form-checkbox>
                      </b-form-group>
                      <p class="mt-4 m-0 text-secondary"><strong>Hinweis: Es wird INSGESAMT ein Gutschein angelegt!</strong></p>
                      <hr class="mb-2 mt-0">
                    </b-col>
                    
                  </b-row>
                  <b-button class="mt-3" type="submit" variant="primary">Gewinnspiel anlegen</b-button>
                  <b-button class="ml-3 mt-3" type="reset" variant="danger">Formular zurücksetzen</b-button>
                </b-form>
                <b-card class="mt-3" header="Form Data Result">
                  <pre class="m-0">{{ form }}</pre>
                </b-card>
              </div>
            </b-row>
          </b-collapse>
        </div>

        <b-row class="justify-content-center m-0 my-5">
        <h1 class="text-secondary">Avy&Dan E-Mail-Templates</h1>        
      </b-row>

        <div class="showcase-invert bg-white m-2 mx-4 mt-5"  style="min-width: 95%" v-if="emailtemplates.length > 0">
          <div class="showcase-header py-4 linear-success text-white" style="position: relative" >
            <h4 class="text-center m-0">E-Mail-Templates</h4>
          </div>
          <b-row class="m-0 justify-content-center">
            <b-col cols="12" style="w-100" v-for="(emailtemplate, index) in emailtemplates" :key="emailtemplate.index">
              <div class="showcase-invert bg-white m-2 mt-4"  style="min-width: 95%" v-if="emailtemplates.length > 0">
                <div class="showcase-header p-2 linear-success text-white" style="position: relative" v-b-toggle="'collapse-template-'+index">
                  <h5 class="text-center m-0">{{emailtemplate.title}}</h5>
                </div>
                <b-collapse :id="'collapse-template-'+index">

                  <b-form @submit.prevent="updateTemplate(emailtemplate)">
                    <b-row class="m-0 justify-content-start">
                      <b-col cols="12" md="8" class="mb-5">
                        <p class="mt-4 m-0 text-secondary"><strong>Einstellungen E-Mail-Template</strong></p>
                        <hr class="mb-2 mt-0">
                        <b-form-group
                          label="Templatetitel:"
                          description="z.B.: Avy&Dan Gewinnspiel 2021 - Gewinner"
                        >
                          <b-form-input
                            v-model="emailtemplate.title"
                            type="text"
                            placeholder="Titel eingeben..."
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="E-Mail-Betreff:"
                          description="z.B.: Avy&Dan Gewinnspiel 2021 - Sie haben gewonnen!"
                        >
                          <b-form-input
                            v-model="emailtemplate.subject"
                            type="text"
                            placeholder="Betreff eingeben..."
                            required
                          ></b-form-input>
                        </b-form-group>

                        <p class="mt-4 m-0"><strong>E-Mail-Content:</strong></p>
                        <quill-editor
                          class="mt-2"
                          ref="myQuillEditor"
                          v-model="emailtemplate.html"
                          :options="editorOption"
                        />

                        <b-button class="mt-3" :disabled="!emailtemplate.html ? true : false" type="submit" variant="primary">Template speichern</b-button>
                        
                      </b-col>
                    </b-row>
                  </b-form>

                  <!-- <h4 class="my-4">Betreff: {{emailtemplate.subject}}</h4>
                  <span v-html="emailtemplate.html"></span> -->
                  <b-button size="sm" variant="danger" class="ml-2 mb-2" @click="$store.dispatch('deleteEmailtemplate', emailtemplate._id)">
                    <b-icon v-if="!updating" icon="trash" aria-hidden="true"></b-icon> <b-spinner v-if="updating" small label="Spinning"></b-spinner> Löschen
                  </b-button>

                  <b-button size="sm" variant="info" class="ml-2 mb-2" @click="sendMailModal = true, html = emailtemplate.html">
                    <font-awesome-icon v-if="!updating" class="mr-1" :icon="['fas', 'paper-plane']"/> <b-spinner v-if="updating" small label="Spinning"></b-spinner> Test Mail senden
                  </b-button>
                </b-collapse>
              </div>
              
              
            </b-col>
          </b-row>
        </div>

        <div class="showcase-invert bg-white m-2 mx-4 mt-5"  style="min-width: 95%" >
          <div class="showcase-header py-4 linear-success text-white" style="position: relative" v-b-toggle.collapse-create-template>
            <h4 class="text-center m-0 text-white">E-Mail-Template erstellen</h4>
          </div>
          <b-collapse id="collapse-create-template">
            <b-form @submit="onSubmitTemplate" @reset="onResetTemplate" >
              <b-row class="m-0 justify-content-start">
                <b-col cols="12" md="8" class="mb-5">
                  <p class="mt-4 m-0 text-secondary"><strong>Einstellungen E-Mail-Template</strong></p>
                  <hr class="mb-2 mt-0">
                  <b-form-group
                    label="Templatetitel:"
                    description="z.B.: Avy&Dan Gewinnspiel 2021 - Gewinner"
                  >
                    <b-form-input
                      v-model="templateform.title"
                      type="text"
                      placeholder="Titel eingeben..."
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="E-Mail-Betreff:"
                    description="z.B.: Avy&Dan Gewinnspiel 2021 - Sie haben gewonnen!"
                  >
                    <b-form-input
                      v-model="templateform.subject"
                      type="text"
                      placeholder="Betreff eingeben..."
                      required
                    ></b-form-input>
                  </b-form-group>

                  <p class="mt-4 m-0"><strong>E-Mail-Content:</strong></p>
                  <quill-editor
                    class="mt-2"
                    ref="myQuillEditor"
                    v-model="templateform.html"
                    :options="editorOption"
                  />

                  <b-button class="mt-3" :disabled="!templateform.html ? true : false" type="submit" variant="primary">Template anlegen</b-button>
                  <b-button class="ml-3 mt-3" type="reset" variant="danger">Formular zurücksetzen</b-button>
                  
                </b-col>
              </b-row>
            </b-form>
              
            <b-card class="mt-3" header="Form Data Result">
              <pre class="m-0">{{ templateform }}</pre>
            </b-card>

          </b-collapse>
        </div>

      </div>
      
    </div>
    </b-row>
    <b-modal hide-footer centered hide-header v-model="sendMailModal">
      <div class="rounded bg-white p-3">
      <p class="mb-4">Testmail senden</p>

      <p class="mb-4 text-danger">Senden Sie NIEMALS Testmails an unbekannte E-Mails!</p>

      <b-form @submit.prevent="sendTestMail">
        <b-form-group label="E-Mail-Adresse:" >
          <b-form-input
            v-model="email"
            required
            placeholder="E-Mail-Adresse eingeben..."
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Anzahl an Mails:" >
          <b-form-input
            v-model="amount"
            required
            type="number"
          ></b-form-input>
        </b-form-group>
        <b-row class="m-0 justify-content-between">
          <b-button type="submit" variant="primary">Senden <b-spinner v-if="updating" small label="Spinning"></b-spinner></b-button>
        </b-row>
        
      </b-form>

      <small>Erfolgreich:</small>
        <b-progress class="mb-2" :max="amount" show-value>
          <b-progress-bar :value="ok" variant="success" :animated="started"></b-progress-bar>
        </b-progress>
        <small>Fehlerhaft:</small>
        <b-progress class="mb-2" :max="amount" show-value>
          <b-progress-bar :value="errors" variant="danger" :animated="started"></b-progress-bar>
        </b-progress>
      </div>
    </b-modal>
  </div>
</template>

<script>

import coreData from '@/mixins/coreDataMixin'
import { uuid } from 'vue-uuid';
import axiosAuth from '@/shared/configs/axios-auth'


export default {
  name: 'Giveaways',
  mixins: [coreData],
  data(){
    return {
      started: false,
      ok: 0,
      errors: 0,
      email: "",
      amount: 1,
      html: null,
      sendMailModal: false,
      editorOption: {
          // Some Quill options...
        },
      uuid: uuid.v1(),
      v1: this.$uuid.v1(),
      // v3: this.$uuid.v3(),
      v4: this.$uuid.v4(),
      templateform: {},
      form: {
        voucher_winner: {},
        voucher_loser: {}
      },
      foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true,
      discount_types: [{value: 'percent', text: 'Prozentualer Nachlass/Rabatt'}, {value: 'fixed_cart', text: 'Fester Warenkorb-Rabatt'}],
  }
  },
  components: {

  },
  computed:{

  },
  watch: {
   
  },
  mounted(){
   
  },
  methods: {
    sendTestMail(){
      this.ok = 0;
      this.errors = 0;
      var payload = {
        email: this.email,
        subject: 'TESTMAIL',
        html: this.html
      }

      var vm = this;
      var index = 0
      this.started = true
      for (index; index < this.amount; index++) {
        // this.$store.dispatch('sendTestmail', payload)
          new Promise((resolve) => {
            axiosAuth.post('/testmail', payload)
            .then(
                res => {
                  console.log(res)
                  vm.ok += 1;
                  resolve()
                }
            )
            .catch(
                error => {
                  console.log(error)
                  vm.errors += 1
                  resolve()
                }
            )
          });  
      }
      this.started = false
      
    },
    updateTemplate(template) {
      this.$store.dispatch('updateEmailtemplate', template)
    },
    onSubmitTemplate(event) {
      this.$store.dispatch('postEmailtemplate', this.templateform)
      event.preventDefault()
      this.templateform = {}
    },
    onResetTemplate(event) {
      event.preventDefault()
      this.templateform = {}
    },
    onSubmit(event) {
      this.$store.dispatch('postGiveaway', this.form)
      event.preventDefault()
      this.form = {
        voucher_winner: {},
        voucher_loser: {}
      }
    },
    onReset(event) {
      event.preventDefault()
      this.form = {
        voucher_winner: {},
        voucher_loser: {}
      }
    }
  }
}
</script>
