    import axiosAuth from '@/shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            emailtemplates: []
        }
    }
    
    const state = getDefaultState()

    const getters = {
        emailtemplates: state => {
            return state.emailtemplates
        }
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        emailtemplates: (state, payload) => {
            state.emailtemplates = payload
        }
    }

    const actions = {
       
        getEmailtemplates: ({commit, dispatch}) =>{
            commit('updating', true)
            axiosAuth.get('/emailtemplates')
            .then(
                res => {
                    commit('updating', false)
                    commit('emailtemplates', res.data)
                }
              )
              .catch(
                error => {
                  commit('updating', false)
                    if(error.response){
                        if(error.response.status == 401) dispatch('logout')
                    }
                }
              )
        },

        postEmailtemplate ({commit, dispatch}, payload) {
            commit('updating', true)
            axiosAuth.post('/emailtemplates', payload)
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('updating', false)
                        dispatch('getEmailtemplates')
                    }
                )
                .catch(
                () => {
                    commit('updating', false)
                }
                )
        },

        updateEmailtemplate: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.patch('/emailtemplates/'+ payload._id, payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getEmailtemplates')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        },

        deleteEmailtemplate: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.delete('/emailtemplates/'+ payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getEmailtemplates')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }