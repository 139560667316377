    import axiosAuth from '../../../shared/configs/axios-auth'
    import router from '@/router'
    const getDefaultState = () => {
        return {
            trash: null,
            users: [],
            authMessage: "",
            accountCreated: null,
            loggedIn: false,
            userName: '',
            userMail: '',
            idToken: null,
            userId: null,
            errorCode: null,
            logOutTimer: null,
            resetOk: false,
            verificationSent: false,
            emailVerified: null,
            userInformations: null,
            userCreated: null,
            isAdmin: false
        }
    }

    const state = getDefaultState()

    const getters = {
        authMessage: state => {
            return state.authMessage;
        },
        accountCreated: state => {
            return state.accountCreated;
        },
        userId: state => {
            return state.userId;
        },
        users: state => {
            return state.users;
        },
        userCreated: state => {
            return state.userCreated;
        },
        emailVerified: state => {
            return state.emailVerified;
        },
        verificationSent: state => {
            return state.verificationSent;
        },
        loggedIn: state => {
            return state.loggedIn;
        },
        userName: state => {
            return state.userName;
        },
        isAdmin: state => {
            return state.isAdmin;
        },
        userMail: state => {
            return state.userMail;
        },
        authError: state => {
            return state.errorCode;
        },
        logOutTimer: state => {
            return state.logOutTimer;
        },
        resetOk: state => {
            return state.resetOk;
        },
        idToken: state => {
            return state.idToken
        },
        userInformations: state => {
            return state.userInformations
        },
    }
   
    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        authMessage: (state, payload) => {
            state.authMessage = payload
        },
        trash: (state, payload) => {
            state.trash = payload
        },
        users: (state, payload) => {
            state.users = payload
        },
        isAdmin: (state, payload) => {
            state.isAdmin = payload
        },
        userCreated: (state, payload) => {
            state.userCreated = payload
        },
        accountCreated: (state, bool) => {
            state.accountCreated = bool
        },
        emailVerified: (state, bool) => {
            state.emailVerified = bool
        },
        verificationSent: (state, bool) => {
            state.verificationSent = bool
        },
        loggedIn: (state, bool) => {
            state.loggedIn = bool;
        },
        resetOk:(state, bool) => {
            state.resetOk = bool
        },
        logOut: (state) => {
            state.loggedIn = false;
            state.userName = null
        },
        userName: (state, payload) => {
            state.userName = payload
        },
        authUser (state, userData) {
            state.idToken = userData.token
            state.userId = userData.userId
            state.userName = userData.userName
            state.loggedIn = true
        },
        authError (state, error) {
            state.errorCode = error
        },
        userInformations (state, error) {
            state.userInformations = error
        },
        clearAuthData (state) {
            state.idToken = null
            state.userName = null
            state.userId = null
            state.logOutTimer = null
            clearInterval(this.interval)
            state.resetOk = false
        }
    }

    const actions = {

        tryAutoLogin ({commit, dispatch}) {
            var token = localStorage.getItem('token')
            if(token){
                if(token.length > 10) {
                    dispatch('userInformations')
                    state.idToken = token
                    commit('authUser', {
                        token: token
                    })
                    axiosAuth.defaults.headers["Authorization"] = token
                    return
                }
            }else{
                if(localStorage.getItem('darkMode')){
                    commit('darkMode', !localStorage.getItem('darkMode'))
                }
                dispatch('logout')       
            }
            
        },

        signup ({commit}, authData) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/auth/register', {
                email: authData.email,
                password: authData.password,
                betakey: authData.betakey
                })
                .then(
                res => {
                    commit('trash', res)
                    commit('updating', false)
                    commit('accountCreated', true)
                }
                )
                .catch(
                error => {
                    commit('authMessage', error.response.data)
                    commit('accountCreated', false)
                    commit('authError', error.response.status)
                    commit('updating', false)
                }
                )
        },

        signupByAdmin ({commit, dispatch}, authData) {
            commit('accountCreated', null)
            commit('userCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/auth/registerbyadmin', authData)
                .then(
                res => {
                    commit('trash', res)
                    commit('userCreated', true)
                    commit('updating', false)
                    commit('accountCreated', true)
                    dispatch('getCompany')
                }
                )
                .catch(
                error => {
                    commit('userCreated', false)
                    commit('authMessage', error.response.data)
                    commit('accountCreated', false)
                    commit('authError', error.response.status)
                    commit('updating', false)
                }
                )
        },

        resetPassword ({commit}, authData) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/auth/resetpassword', {
                email: authData.email,
                })
                .then(
                res => {
                    commit('trash', res)
                    commit('updating', false)           }
                )
                .catch(
                error => {
                    commit('authMessage', error.response.data)
                    commit('authError', error.response.status)
                    commit('updating', false)
                }
                )
        },

        userInformations ({commit}) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.get('/users/me')
                .then(
                res => {
                    commit('updating', false) 
                    commit('userInformations', res.data) 
                }
                )
                .catch(
                error => {
                    commit('authMessage', error.response.data)
                    commit('authError', error.response.status)
                    commit('updating', false)
                }
                )
        },

        getUsers ({commit},payload) {
            commit('updating', true)
            axiosAuth.get('/users/profiles/comp/' + payload._id)
                .then(
                res => {
                    commit('updating', false) 
                    commit('users', res.data) 
                }
                )
                .catch(
                error => {
                    commit('authMessage', error.response.data)
                    commit('authError', error.response.status)
                    commit('updating', false)
                }
                )
        },

        updateUserByAdmin: ({commit, dispatch}, payload) => {
            commit('userCreated', null)
            commit('updating', true)
            axiosAuth.patch('/users/' + payload._id , payload.data)
            .then(
                res => {
                    commit('userCreated', true)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('userInformations')
                }
              )
            .catch(
                () => {
                    commit('userCreated', false)
                    commit('updating', false)
                }
            ) 
        },

        updateUserInformations: ({commit, dispatch}, payload) => {
            commit('userCreated', null)
            commit('updating', true)
            axiosAuth.patch('/users/me', payload)
            .then(
                res => {
                    commit('userCreated', true)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('userInformations')
                }
              )
            .catch(
                () => {
                    commit('userCreated', false)
                    commit('updating', false)
                }
            ) 
        },
        checkLogin () { 
            axiosAuth.post('/auth-customers/check-login')
            .then(
                () => {
                                  
                }
                )
                .catch(
                () => {
                    localStorage.clear()
                    router.push('/').catch(err => console.log(err.what))    
                }
                )
        },

        loginCustomer ({commit}, authData) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/auth-customers/login' , {
                email: authData.email
            })
            .then(
                res => {
                    commit('loggedIn', true)
                    commit('resetState')
                    state.idToken = res.data.token        
                    localStorage.setItem('token', res.data.token)
                    if(res.data.username){
                        localStorage.setItem('username', res.data.username)
                        localStorage.setItem('email', authData.email)
                    }
                    axiosAuth.defaults.headers["Authorization"] = res.data.token;
                    commit('updating', false)
                    router.push('/live').catch(err => console.log(err.what))                             
                }
                )
                .catch(
                error => {
                    commit('updating', false)
                    commit('authError', error.response.status)
                    commit('authMessage', error.response.data)
                }
                )
            },

        login ({commit}, authData) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/auth/login' , {
                email: authData.email,
                password: authData.password
            })
            .then(
                res => {
                    commit('resetState')          
                    localStorage.setItem('token', res.data.token)
                    commit('isAdmin', res.data.token)
                    axiosAuth.defaults.headers["Authorization"] = res.data.token;
                    localStorage.setItem('email', authData.email)
                    commit('updating', false)
                    commit('isAdmin', true)
                    router.push('/dashboard').catch(err => console.log(err.what))                             
                }
                )
                .catch(
                error => {
                    commit('updating', false)
                    commit('authError', error.response.status)
                    commit('authMessage', error.response.data)
                }
                )
            },

            logout ({commit}) {
                    var cookie = false

                    if(localStorage.getItem('cookie')){
                        cookie = true
                    }

                    localStorage.clear()
                    if(cookie){
                        localStorage.setItem('cookie', true)
                    }

                    commit('resetState')
                    commit('loggedIn', false)
                    commit('updating', false)
                    router.push('/login').catch(err => console.log(err.what))
            },

            
        }

        export default {
            state,
            getters,
            mutations,
            actions
        }
