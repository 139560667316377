<template>
  <div class="home" style="padding-top: 90px; margin-bottom: 150px" >
    <b-row class="m-0 justify-content-center" v-if="isAdmin">
    <div class="w-100" style="max-width: 1700px">
 
      <b-row class="justify-content-center m-0 mb-5">
        <h1 class="text-secondary">Avy&Dan Bestellungen</h1>        
      </b-row>
      
      <div>
        <div class="showcase-invert bg-white shadow m-2 mx-4"  style="min-width: 95%" >
          <div class="showcase-header py-4 linear-info text-white hover" style="position: relative" @click="$router.push('/orders').catch(err => {})">
            <h4 class="text-center m-0">Bestellungen</h4>
          </div>
          <b-row class="m-0 justify-content-center">
            <b-table :busy.sync="updating" hover="hover" striped="striped" borderless responsive :items="orders" :fields="orderFields" :current-page="currentPage" per-page="10">
              <template #table-busy>
                <div class="text-center text-success my-5">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template> 
              <template v-slot:cell(status)="data">
                <b-badge class="" :variant="getBadgeVariant(data.item.status)">{{ data.item.status }}</b-badge>
              </template>
              <template v-slot:cell(date_created)="data">
                {{ data.item.date_created | moment("DD.MM.YYYY HH:MM") }}
              </template>
              <template v-slot:cell(date_paid)="data">
                {{ data.item.date_paid | moment("DD.MM.YYYY HH:MM") }}
              </template>
              <template #cell(show_details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  Details
                </b-button>

              </template>
              <template #row-details="row">
                <b-card>
                  <b-table hover="hover" striped="striped" borderless responsive :items="[row.item.billing]" :fields="customerFields"  :current-page="currentPage" per-page="10">
                  </b-table>
                  <b-table hover="hover" striped="striped" borderless responsive :items="row.item.line_items" :fields="itemFields" :current-page="currentPage" per-page="10">
                  </b-table>
                  
                  <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                </b-card>
              </template>
            </b-table>
            <nav>
              <b-pagination size="sm" :total-rows="orders.length" per-page="10" v-model="currentPage" prev-text="letzte" next-text="nächste" hide-goto-end-buttons/>
            </nav>
            <!-- <b-table hover="hover" striped="striped" borderless small="small" responsive :items="orders"  :current-page="currentPage" per-page="10">
            </b-table> -->
          </b-row>
        </div>

      </div>
      
    </div>
    </b-row>

  </div>
</template>

<script>

import coreData from '@/mixins/coreDataMixin'

export default {
  name: 'Orders',
  mixins: [coreData],
  data(){
    return {
      currentPage: 1,
      orderFields: [
        {key: 'id', label: 'Id', sortable: true},
        {key: 'status', label: 'Status', sortable: true},
        {key: 'currency', label: 'Währung', sortable: true},
        {key: 'date_created', label: 'Bestelldatum', sortable: true},
        {key: 'date_paid', label: 'Bezahldatum', sortable: true},
        {key: 'discount_total', label: 'Rabatt', sortable: true},
        {key: 'shipping_total', label: 'Versandkosten', sortable: true},
        {key: 'total', label: 'Gesamtkosten', sortable: true},
        {key: 'payment_method_title', label: 'Zahlungsmethode'},
        {key: 'show_details', label: 'Details'},
      ],
      itemFields: [
        {key: 'id', label: 'Id', sortable: true},
        {key: 'name', label: 'Name', sortable: true},
        {key: 'quantity', label: 'Anzahl', sortable: true},
        {key: 'total', label: 'Gesamtpreis', sortable: true},
      ],
      customerFields: [
        {key: 'first_name', label: 'Vorname'},
        {key: 'last_name', label: 'Nachname'},
        {key: 'company', label: 'Firma'},
        {key: 'adress_1', label: 'Adresse 1'},
        {key: 'adress_2', label: 'Adresse 2'},
        {key: 'city', label: 'Stadt'},
        {key: 'postcode', label: 'PLZ'},
        {key: 'country', label: 'Land'},
        {key: 'email', label: 'E-Mail'},
        {key: 'phone', label: 'Telefon'},
      ],
      couponfields: [
        {key: 'code', label: 'Code', sortable: true},
        {key: 'amount', label: 'Betrag', sortable: true},
        {key: 'discount_type', label: 'Gutscheintyp', sortable: true},
        {key: 'description', label: 'Beschreibung', sortable: true},
        {key: 'usage_limit', label: 'Limit', sortable: true},
        {key: 'free_shipping', label: 'Gratis Lieferung', sortable: true},
        {key: 'email_restrictions', label: 'E-Mails', sortable: true},
        {key: 'delete', label: 'Entfernen'},
      ],
     
    }
  },
  components: {

  },
  computed:{
   
  },
  watch: {
    
  },

  methods: {
    getBadgeVariant(status){
      if(status == 'cancelled'){
        return 'danger'
      }

      if(status == 'processing'){
        return 'warning'
      }

      if(status == 'on-hold'){
        return 'warning'
      }

      if(status == 'refunded'){
        return 'info'
      }

      if(status == 'refund-cancelled'){
        return 'danger'
      }

      if(status == 'refund-approved'){
        return 'success'
      }

      if(status == 'refund-requested'){
        return 'warning'
      }

      return 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
.outer {
  display: table;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white !important;
}

  .showcase-admin {
    width: 100%;
    max-width: 2000px;
    height: 850px;
    background-repeat: no-repeat;
    position: relative;
  }

  .custom-input {
    border-radius: 0 !important;
    border: 2px solid  rgb(51,211,241) !important;
  }

  .custom-input-chat {
    max-width: 350px !important;
    border-radius: 0 !important;
    border: none !important;
    background-color:  rgb(51,211,241) !important;
    color: white !important;
  }

  .custom-button {
    border-radius: 0 !important;
    border: none !important;
    color: white;
    background-color:  rgb(51,211,241) !important;
  }

  .quote-showcase {
    width: 670px;
    max-width: 90vw;
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: justify;
  }

  .quote {
     font-weight: 400 !important;
  }
  
  .ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border-radius: 20px;
  }

  .center {
    position: relative;
  }

  .center p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>