<template>
  <div class="home" style="padding-top: 90px; margin-bottom: 150px" >
    <b-row class="m-0 justify-content-center" v-if="isAdmin">
    <div class="w-100" style="max-width: 1700px">
 
      <b-row class="justify-content-center m-0 mb-5">
        <h1 class="text-secondary">Avy&Dan Dashboard</h1>        
      </b-row>
      
      <div>
        <div class="showcase-invert bg-white  m-2 mx-4"  style="min-width: 95%" >
          <div class="showcase-header py-4 linear-info text-white hover" style="position: relative" @click="$router.push('/orders').catch(err => {})">
            <h4 class="text-center m-0">Bestellungen</h4>
          </div>
          <b-row class="m-0 justify-content-center">
            <line-example v-if="chartData" style="width: 100%; max-height: 300px" :chartData="chartData"></line-example>
          </b-row>
        </div>

        <div class="showcase-invert bg-white  m-2 mx-4 mt-5"  style="min-width: 95%" >
          <div class="showcase-header py-4 linear-info text-white hover" style="position: relative" @click="$router.push('/giveaways').catch(err => {})">
            <h4 class="text-center m-0 text-white">Gewinnspiele</h4>
          </div>
          <b-row class="m-0 justify-content-center">
            <b-col cols="12" style="w-100" v-for="(giveaway) in giveaways" :key="giveaway.index">
              <div class="showcase-invert bg-white p-0 m-2 mt-4"  style="min-width: 95%" v-if="giveaways.length > 0">

                <div class="showcase-header p-2 mt-1 linear-info text-white hover" style="position: relative" @click="$router.push('/giveaways/' + giveaway._id).catch(err => {})">
                  <h5 class="text-center m-0">{{giveaway.title}}</h5>
                </div>
              </div>
              
            </b-col>
          </b-row>
        </div>

        <div class="showcase-invert bg-white  m-2 mx-4 mt-5"  style="min-width: 95%" v-if="coupons.length > 0">
          <div class="showcase-header py-4 linear-info text-white" style="position: relative">
            <h4 class="text-center m-0">Aktive Gutscheine ({{ coupons.length }})</h4>
          </div>
          <b-row class="m-0 justify-content-center">

            <div class="p-4 w-100">
              <b-table hover="hover" striped="striped" v-if="coupons.length > 0" borderless small="small" responsive :items="coupons" :fields="couponfields" :current-page="currentPageCoupons" per-page="10">
              <template v-slot:cell(code)="data">
                <p class="m-0 mt-2">{{ data.item.code }}</p>
              </template>

              <template v-slot:cell(amount)="data">
                <p class="m-0 mt-2">{{ data.item.amount }}</p>
              </template>

              <template v-slot:cell(discount_type)="data">
                <p class="m-0 mt-2">
                  {{ data.item.discount_type == 'fixed_cart' ? 'Fester Warenkorb-Rabatt' : null }}
                  {{ data.item.discount_type == 'percent' ? 'Prozentualer Rabatt' : null }}
                </p>
              </template>

              <template v-slot:cell(description)="data">
                <p class="m-0 mt-2"><span v-html="data.item.description"></span></p>
                <!-- <p class="m-0 mt-2">{{ data.item.description }}</p> -->
              </template>

              <template v-slot:cell(usage_limit)="data">
                <p class="m-0 mt-2">{{ data.item.usage_count }}  / {{ data.item.usage_limit ? data.item.usage_limit : '&#x221E;'}}</p>
              </template>

              <template v-slot:cell(free_shipping)="data">
                <b-icon v-if="data.item.free_shipping === true" class="text-success h1 m-0 p-0" icon="check" aria-hidden="true"></b-icon>
                <b-icon v-if="!data.item.free_shipping" class="text-danger h1 m-0 p-0" icon="x" aria-hidden="true"></b-icon>
              </template> 

              <template v-slot:cell(email_restrictions)="data">
                <div v-if="data.item.email_restrictions.length == 0">
                  keine Beschränkung
                </div>
                <div v-else v-for="email in data.item.email_restrictions" :key="email.index">
                  {{ email }}
                </div>
              </template>

              <template v-slot:cell(date_expires)="data">
                <p class="m-0 mt-2">{{ data.item.date_expires | moment('DD.MM.YYYY') }}</p>
              </template>

              <template v-slot:cell(delete)="data">
                <div class="justify-content-center pt-2">
                  <b-button size="sm" variant="danger" class="ml-2 mb-2" @click="$store.dispatch('deleteCoupon', data.item.id )">
                    <b-icon v-if="!updating" icon="trash" aria-hidden="true"></b-icon>
                    <b-spinner v-if="updating" small label="Spinning"></b-spinner>
                  </b-button>
                </div>
              </template>
              </b-table>
              <nav>
                <b-pagination size="sm" :total-rows="coupons.length" per-page="10" v-model="currentPageCoupons" prev-text="letzte" next-text="nächste" hide-goto-end-buttons/>
              </nav>          
            </div>
          </b-row>
        </div>
      </div>
      
    </div>
    </b-row>

  </div>
</template>

<script>

import coreData from '@/mixins/coreDataMixin'
import LineExample from './charts/LineExample.vue'

export default {
  name: 'Dashboard',
  mixins: [coreData],
  data(){
    return {
      currentPageCoupons: 1,
      couponfields: [
        {key: 'code', label: 'Code', sortable: true},
        {key: 'amount', label: 'Betrag', sortable: true},
        {key: 'discount_type', label: 'Gutscheintyp', sortable: true},
        {key: 'description', label: 'Beschreibung', sortable: true},
        {key: 'usage_limit', label: 'Limit', sortable: true},
        {key: 'free_shipping', label: 'Gratis Lieferung', sortable: true},
        {key: 'email_restrictions', label: 'E-Mails', sortable: true},
        {key: 'delete', label: 'Entfernen'},
        {key: 'date_expires', label: 'Ablaufdatum'}
      ],
      chartData: null
    }
  },
  components: {
    LineExample
  },
  computed:{
    // chartData(){
    //   return {
    //       labels: this.getDaysInMonth(new Date().getMonth(), new Date().getFullYear()),
    //       datasets: [
    //         {
    //           label: 'Bestellungen',
    //           data: this.getOrders(),
    //           fill: false,
    //           borderColor: '#28a745',
    //           backgroundColor: '#28a745',
    //           borderWidth: 1
    //         },
    //         {
    //           label: 'Stornierungen',
    //           data: this.getRefunds(),
    //           fill: false,
    //           borderColor: '#dc3545',
    //           backgroundColor: '#dc3545',
    //           borderWidth: 1
    //         }
    //       ]
    //     }
    // }
  },
  watch: {
    orders(){
      if(this.orders.length > 0){
        this.chartData = this.calcChartData()
      }
    }
  },
  mounted(){
    // console.log(this.getDaysInMonth(new Date().getMonth(), new Date().getFullYear()))
    this.getOrders()
    if(this.orders.length > 0){
        this.chartData = this.calcChartData()
      }
  },
  methods: {
    calcChartData(){
      return {
          labels: this.getDaysInMonth(new Date().getMonth(), new Date().getFullYear()),
          datasets: [
            {
              label: 'Bestellungen',
              data: this.getOrders(),
              fill: false,
              borderColor: '#28a745',
              backgroundColor: '#28a745',
              borderWidth: 1
            },
            {
              label: 'Stornierungen',
              data: this.getCancels(),
              fill: false,
              borderColor: '#dc3545',
              backgroundColor: '#dc3545',
              borderWidth: 1
            },
            {
              label: 'Erstattungen',
              data: this.getRefunds(),
              fill: false,
              borderColor: '#ffc107',
              backgroundColor: '#ffc107',
              borderWidth: 1
            }
          ]
        }
    },
    getOrders(){
      var days = this.getDatesInMonth(new Date().getMonth(), new Date().getFullYear());
      var orderData = []
      days.forEach((element, index) => {

        orderData[index] = this.orders.filter(el => new Date(el.date_created).getDate() == element.getDate() && new Date(el.date_created).getMonth() == element.getMonth()).length
      });
      return orderData
    },
    getCancels(){
      var days = this.getDatesInMonth(new Date().getMonth(), new Date().getFullYear());
      var orderData = []
      days.forEach((element, index) => {

        orderData[index] = this.orders.filter(el => new Date(el.date_created).getDate() == element.getDate() && new Date(el.date_created).getMonth() == element.getMonth() && el.status == 'cancelled').length
      });
      return orderData
    },
    getRefunds(){
      var days = this.getDatesInMonth(new Date().getMonth(), new Date().getFullYear());
      var orderData = []
      days.forEach((element, index) => {

        orderData[index] = this.orders.filter(el => new Date(el.date_created).getDate() == element.getDate() && new Date(el.date_created).getMonth() == element.getMonth() && el.status.includes('refund')).length
      });
      return orderData
    }
  }
}
</script>

<style lang="scss" scoped>
.outer {
  display: table;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white !important;
}

  .showcase-admin {
    width: 100%;
    max-width: 2000px;
    height: 850px;
    background-repeat: no-repeat;
    position: relative;
  }

  .custom-input {
    border-radius: 0 !important;
    border: 2px solid  rgb(51,211,241) !important;
  }

  .custom-input-chat {
    max-width: 350px !important;
    border-radius: 0 !important;
    border: none !important;
    background-color:  rgb(51,211,241) !important;
    color: white !important;
  }

  .custom-button {
    border-radius: 0 !important;
    border: none !important;
    color: white;
    background-color:  rgb(51,211,241) !important;
  }

  .quote-showcase {
    width: 670px;
    max-width: 90vw;
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: justify;
  }

  .quote {
     font-weight: 400 !important;
  }
  
  .ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border-radius: 20px;
  }

  .center {
    position: relative;
  }

  .center p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>