    import axiosAuth from '@/shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            orders: []
        }
    }
    
    const state = getDefaultState()

    const getters = {
        orders: state => {
            return state.orders
        }
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        orders: (state, payload) => {
            state.orders = payload
        }
    }

    const actions = {
       
        getOrders: ({commit, dispatch}) =>{
            commit('updating', true)
            axiosAuth.get('/orders')
            .then(
                res => {
                    commit('updating', false)
                    commit('orders', res.data)
                }
              )
              .catch(
                error => {
                  commit('updating', false)
                    if(error.response){
                        if(error.response.status == 401) dispatch('logout')
                    }
                }
              )
        },

        // postCoupon ({commit, dispatch}, payload) {
        //     commit('updating', true)
        //     axiosAuth.post('/orders', payload)
        //         .then(
        //             res => {
        //                 commit('trash', res)
        //                 commit('updating', false)
        //                 dispatch('getorders')
        //             }
        //         )
        //         .catch(
        //         () => {
        //             commit('updating', false)
        //         }
        //         )
        // },

        // updateCoupon: ({commit, dispatch}, payload) => {
        //     commit('loadingData', true)
        //     commit('updating', true)
        //     axiosAuth.patch('/orders/'+ payload._id, payload)
        //     .then(
        //         res => {
        //             commit('loadingData', false)
        //             commit('trash', res)
        //             commit('updating', false)
        //             dispatch('getorders')
        //         }
        //       )
        //     .catch(
        //         () => {
        //             commit('loadingData', false)
        //             commit('updating', false)
        //         }
        //     ) 
        // },

        // deleteCoupon: ({commit, dispatch}, payload) => {
        //     commit('loadingData', true)
        //     commit('updating', true)
        //     axiosAuth.delete('/orders/'+ payload)
        //     .then(
        //         res => {
        //             commit('loadingData', false)
        //             commit('trash', res)
        //             commit('updating', false)
        //             dispatch('getorders')
        //         }
        //       )
        //     .catch(
        //         () => {
        //             commit('loadingData', false)
        //             commit('updating', false)
        //         }
        //     ) 
        // }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }