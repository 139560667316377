<script>
  import { Bar } from 'vue-chartjs'

  export default {
    extends: Bar,
    props: {
      chartData: null
    },
    data () {
      return {
        
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>

<style lang="scss" scoped>
#line-chart {
  width: 100% !important;
}
</style>