    import axiosAuth from '@/shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            giveaways: []
        }
    }
    
    const state = getDefaultState()

    const getters = {
        giveaways: state => {
            return state.giveaways
        }
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        giveaways: (state, payload) => {
            state.giveaways = payload
        }
    }

    const actions = {
       
        getGiveaways: ({commit, dispatch}) =>{
            commit('updating', true)
            axiosAuth.get('/giveaways')
            .then(
                res => {
                    commit('updating', false)
                    commit('giveaways', res.data)
                    dispatch('getCustomers')
                }
              )
              .catch(
                error => {
                  commit('updating', false)
                    if(error.response){
                        if(error.response.status == 401) dispatch('logout')
                    }
                }
              )
        },

        postGiveaway ({commit, dispatch}, payload) {
            commit('updating', true)
            axiosAuth.post('/giveaways', payload)
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('updating', false)
                        dispatch('getGiveaways')
                    }
                )
                .catch(
                () => {
                    commit('updating', false)
                }
                )
        },

        updateGiveaway: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.patch('/giveaways/'+ payload._id, payload)
            .then(
                res => {
                    console.log(res)
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getGiveaways')
                    
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        },

        deleteGiveaway: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.delete('/giveaways/'+ payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getGiveaways')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }