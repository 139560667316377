    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            customers: null,
            verificationMessage: ""
        }
    }
    
    const state = getDefaultState()

    const getters = {
        customers: state => {
            return state.customers
        },
        verificationMessage: state => {
            return state.verificationMessage
        }
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        customers: (state, payload) => {
            state.customers = payload
        },
        verificationMessage: (state, payload) => {
            state.verificationMessage = payload
        }
    }

    const actions = {
       
        getCustomers: ({commit, dispatch}) =>{
            commit('updating', true)
            axiosAuth.get('/customers')
            .then(
                res => {
                    console.log(res)
                    commit('updating', false)
                    commit('customers', res.data)
                }
              )
              .catch(
                error => {
                  commit('updating', false)
                    if(error.response){
                        if(error.response.status == 401) dispatch('logout')
                    }
                }
              )
        },

        postCustomer ({commit, dispatch}, payload) {
            commit('authError', null)
            commit('authMessage', null)
            commit('updating', true)
            axiosAuth.post('/customers', payload)
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('updating', false)
                        dispatch('getCustomers')
                    }
                )
                .catch(
                error => {
                    commit('authError', error.response.status)
                    commit('authMessage', error.response.data)
                    commit('updating', false)
                }
                )
        },

        postCustomerImport ({commit, dispatch}, payload) {
            commit('authError', null)
            commit('authMessage', null)
            commit('updating', true)
            axiosAuth.post('/customers', payload)
                .then(
                    res => {
                        commit('trash', res)
                        dispatch('getCustomers')
                        return true
                    }
                )
                .catch(
                error => {
                    commit('authError', error.response.status)
                    commit('authMessage', error.response.data)
                    return false
                }
                )
        },

        updateCustomer: ({commit}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.patch('/customers/'+ payload._id, payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    //dispatch('getCustomers')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        },

        deleteCustomer: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.delete('/customers/'+ payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getCustomers')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        },

        verifyCustomer ({commit}, payload) {
            console.log(payload)
            commit('updating', true)
            axiosAuth.post('/customers/verify', payload)
                .then(
                    res => {
                        console.log(res)

                        commit('verificationMessage', res.data)
                        commit('updating', false)
                    }
                )
                .catch(
                () => {

                    commit('updating', false)
                }
                )
        },

        resetWinners: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.patch('/customers/reset/'+ payload)
            .then(
                res => {
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getCustomers')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        },

        deleteAllCustomers: ({commit, dispatch}, payload) => {
            commit('loadingData', true)
            commit('updating', true)
            axiosAuth.delete('/customers/all/' + payload)
            .then(
                res => {
                    console.log(res)
                    commit('loadingData', false)
                    commit('trash', res)
                    commit('updating', false)
                    dispatch('getCustomers')
                }
              )
            .catch(
                () => {
                    commit('loadingData', false)
                    commit('updating', false)
                }
            ) 
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }